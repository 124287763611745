import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import moment from "moment";

import FetchModule from "../../../Model/Network/FetchModule";

import WaitIcon from '../../../Image/WaitIcon.png';
import DownloadIcon from '../../../Image/DownloadIcon.png';
import UrbanfitInvitationalSubmitModel from "./UrbanfitInvitationalSubmitModal";
import UrbanfitInvitationalSubmitViewModel from "./UrbanfitInvitationalSubmitViewModal";

export default function UrbanfitInvitationalScaledEvent3(props) {
  const { eventId, totalVideoWidth, totalVideoHeight, setIsCompleteSubmit } = props;

  const { t } = useTranslation();

  const section = 'C';

  const [min, setMin] = useState(0);
  const [sec, setSec] = useState(0);

  const [completeRep, setCompleteRep] = useState(0);
  const [completeRound, setCompleteRound] = useState(0);
  const [lastExerciseRep, setLastExerciseRep] = useState(0);

  const [tieBreakMin, setTieBreakMin] = useState(0);
  const [tieBreakSec, setTieBreakSec] = useState(0);
  // const [lastExercise, setLastExercise] = useState('');
  // const [lastExerciseRep, setLastExerciseRep] = useState(0);
  // const [firstExercise, setFirstExercise] = useState(0);
  // const [secondExercise, setSecondExercise] = useState(0);

  const [firstMemberMin, setFirstMemberMin] = useState(0);
  const [firstMemberSec, setFirstMemberSec] = useState(0);
  const [secondMemberMin, setSecondMemberMin] = useState(0);
  const [secondMemberSec, setSecondMemberSec] = useState(0);

  const [members, setMembers] = useState([]);

  const [link, setLink] = useState('');
  const [lastExercise, setLastExercise] = useState('');
  const [imgBase64, setImgBase64] = useState([]);
  const [isClickedSubmit, setIsClickedSubmit] = useState(false);
  // const [isSuccess, setIsSuccess] = useState({ value: '완료하였습니다' });
  const [isSuccessA, setIsSuccessA] = useState({ value: '완료하였습니다' });
  const [isSuccessB, setIsSuccessB] = useState({ value: '완료하였습니다' });

  const [isRx, setIsRx] = useState(true);
  const [isExistRecord, setIsExistRecord] = useState(false);
  const [bodyWeight, setBodyWeight] = useState(0);
  const [point, setPoint] = useState(0);

  const [viewIsRx, setViewIsRx] = useState(false);
  const [viewMin, setViewMin] = useState(0);
  const [viewSec, setViewSec] = useState(0);
  const [viewCompleteRep, setViewCompleteRep] = useState(0);
  const [viewTieBreakMin, setViewTieBreakMin] = useState(0);
  const [viewTieBreakSec, setViewTieBreakSec] = useState(0);
  // const [viewLastExercise, setViewLastExercise] = useState(0);
  // const [viewLastExerciseRep, setViewLastExerciseRep] = useState(0);
  const [viewFirstExercise, setViewFirstExercise] = useState(0);
  const [viewSecondExercise, setViewSecondExercise] = useState(0);
  const [viewCompleteRound, setViewCompleteRound] = useState(0);
  const [viewLastExerciseRep, setViewLastExerciseRep] = useState(0);

  const [viewFirstMemberMin, setViewFirstMemberMin] = useState(0);
  const [viewFirstMemberSec, setViewFirstMemberSec] = useState(0);
  const [viewSecondMemberMin, setViewSecondMemberMin] = useState(0);
  const [viewSecondMemberSec, setViewSecondMemberSec] = useState(0);

  const [viewLink, setViewLink] = useState('');
  const [viewLastExercise, setViewLastExercise] = useState('');
  const [picture, setPicture] = useState('');

  // 가장 최근기록 가져오기 //
  useEffect(() => {
    let requestData = {
      eventId: eventId,
      section: section
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataOfEvent('record/team_of_4/recently', 'POST', requestData);
        // console.log(responseData.data);
        if (responseData.status === 200) {
          let newMembers = responseData.data.members.slice();

          newMembers.sort((a, b) => a.gender.localeCompare(b.gender) || b.division.localeCompare(a.division) || a.memberId - b.memberId);
          // console.log(newMembers);
          setMembers(newMembers);

          if (responseData.data.eventRecord === undefined) setIsExistRecord(false);
          else {

            // if (responseData.data.time === 0) setIsSuccess({value: '완료하였습니다'});
            // else setIsSuccess({value: '타임켑안에 못했어요'});

            setIsExistRecord(true);

            if (parseInt(responseData.data.firstExercise) === 0) setIsSuccessA({ value: '완료하였습니다' });
            else setIsSuccessA({ value: '타임캡안에 못했어요' });

            if (parseInt(responseData.data.secondExercise) === 0) setIsSuccessB({ value: '완료하였습니다' });
            else setIsSuccessB({ value: '타임캡안에 못했어요' });

            let time = parseInt(responseData.data.eventRecord.time);
            let decimalPoint = (responseData.data.eventRecord.time % 1).toFixed(2);

            setViewMin(Math.floor(time / 60));
            setMin(Math.floor(time / 60));

            if (decimalPoint === '0.00') {
              setViewSec(time % 60);
              setSec(time % 60);
            }
            else {
              setViewSec((time % 60) + parseFloat(decimalPoint));
              setSec((time % 60) + parseFloat(decimalPoint));
            }

            let tieBreakTime = parseInt(responseData.data.eventRecord.tieBreak);
            let tieBreakDecimalPoint = (responseData.data.eventRecord.tieBreak % 1).toFixed(2);

            setViewTieBreakMin(Math.floor(tieBreakTime / 60));
            setTieBreakMin(Math.floor(tieBreakTime / 60));

            if (tieBreakDecimalPoint === '0.00') {
              setViewTieBreakSec((tieBreakTime % 60));
              setTieBreakSec((tieBreakTime % 60));
            }
            else {
              setViewTieBreakSec((tieBreakTime % 60) + parseFloat(tieBreakDecimalPoint));
              setTieBreakSec((tieBreakTime % 60) + parseFloat(tieBreakDecimalPoint));
            }

            let firstMemberTime = parseInt(responseData.data.eventRecord.firstExercise);
            let firstMemberDecimalPoint = (parseInt(responseData.data.eventRecord.firstExercise) % 1).toFixed(2);
            // console.log(time);
            // console.log(decimalPoint);
            // console.log(firstMemberTime);
            // console.log(firstMemberDecimalPoint);
            setViewFirstMemberMin(Math.floor(firstMemberTime / 60));
            setFirstMemberMin(Math.floor(firstMemberTime / 60));

            if (firstMemberDecimalPoint === '0.00') {
              setViewFirstMemberSec(firstMemberTime % 60);
              setFirstMemberSec(firstMemberTime % 60);
            }
            else {
              setViewFirstMemberSec((firstMemberTime % 60) + parseFloat(firstMemberDecimalPoint));
              setFirstMemberSec((firstMemberTime % 60) + parseFloat(firstMemberDecimalPoint));
            }

            let secondMemberTime = parseInt(responseData.data.eventRecord.secondExercise);
            let secondMemberDecimalPoint = (parseInt(responseData.data.eventRecord.secondExercise) % 1).toFixed(2);

            setViewSecondMemberMin(Math.floor(secondMemberTime / 60));
            setSecondMemberMin(Math.floor(secondMemberTime / 60));

            if (secondMemberDecimalPoint === '0.00') {
              setViewSecondMemberSec((secondMemberTime % 60));
              setSecondMemberSec((secondMemberTime % 60));
            }
            else {
              setViewSecondMemberSec((secondMemberTime % 60) + parseFloat(secondMemberDecimalPoint));
              setSecondMemberSec((secondMemberTime % 60) + parseFloat(secondMemberDecimalPoint));
            }

            setLastExerciseRep(responseData.data.eventRecord.lastExerciseRep);
            setViewLastExerciseRep(responseData.data.eventRecord.lastExerciseRep);

            setCompleteRep(responseData.data.eventRecord.completeRep);
            setCompleteRound(responseData.data.eventRecord.completeRound);
            setLastExercise(responseData.data.eventRecord.lastExercise);

            setViewCompleteRep(responseData.data.eventRecord.completeRep);
            setViewCompleteRound(responseData.data.eventRecord.completeRound);
            setViewLastExercise(responseData.data.eventRecord.lastExercise);

            setViewLink(responseData.data.eventRecord.link);
            setPicture(responseData.data.eventRecord.picture);
          }
        }
        else if (responseData.status === 201) {
          setIsExistRecord(false);
        }
      }
    )();
  }, [])

  // 체점 기준표 다운로드 //
  function downloadPDF() {
    try {
      let fileName = 'Urbanfit_Invitational_Event3_RuleBook.pdf';
      // https://link-picture.s3.ap-northeast-2.amazonaws.com/event/2025_URBANFIT_INVITATIONAL/Urbanfit_Invitational_Event1_RuleBook.pdf
      window.ReactNativeWebView.postMessage(JSON.stringify({ downloadURL: 'https://picture.link-zone.org/event/2025_URBANFIT_INVITATIONAL', fileName: fileName }));
      // window.ReactNativeWebView.postMessage(JSON.stringify({ downloadURL: 'https://link-picture.s3.ap-northeast-2.amazonaws.com/event/believer', fileName: fileName }));
    }
    catch (e) {
      console.log("Web Message Post Error : " + e);
    }
  }

  // 제출하기 클릭시 //
  function onClickRecordAndSubmit() {

    // if (moment().format('YYYY.MM.DD HH:mm:ss') > moment('2023-11-05T00:00:00').format('YYYY.MM.DD HH:mm:ss')) {
    //   alert('제출 기한이 마감되었습니다.');
    //   return;
    // }

    // 제출하기 상태일 경우 //
    if (isClickedSubmit) {

      let finalMin = min === '' ? 0 : parseInt(min * 60);
      let finalSec = sec === '' ? 0 : parseFloat(sec);
      let finalTieBreakMin = tieBreakMin === '' ? 0 : parseInt(tieBreakMin * 60);
      let finalTieBreakSec = tieBreakSec === '' ? 0 : parseFloat(tieBreakSec);
      let finalCompleteRep = completeRep === '' ? 0 : completeRep;
      let finalCompleteRound = completeRound === '' ? 0 : completeRound;

      let finalLastExerciseRep = lastExerciseRep === '' ? 0 : lastExerciseRep;
      let finalFirstMemberMin = firstMemberMin === '' ? 0 : parseInt(firstMemberMin * 60);
      let finalFirstMemberSec = firstMemberSec === '' ? 0 : parseFloat(firstMemberSec);
      let finalSecondMemberMin = secondMemberMin === '' ? 0 : parseInt(secondMemberMin * 60);
      let finalSecondMemberSec = secondMemberSec === '' ? 0 : parseFloat(secondMemberSec);
      // let finalFirstExercise = firstExercise === '' ? 0 : firstExercise;
      // let finalSecondExercise = secondExercise === '' ? 0 : secondExercise;

      if ((link !== ''
        && !link.includes('https://youtube.com/shorts/')
        && !link.includes('https://www.youtube.com/watch?v=')
        && !link.includes('https://m.youtube.com/watch?v=')
        && !link.includes('https://youtube.com/watch?v=')
        && !link.includes('https://youtu.be/'))) {
        alert('영상링크를 정확히 입력해주세요');
        return;
      }
      else if ((lastExercise !== ''
        && !lastExercise.includes('https://youtube.com/shorts/')
        && !lastExercise.includes('https://www.youtube.com/watch?v=')
        && !lastExercise.includes('https://m.youtube.com/watch?v=')
        && !lastExercise.includes('https://youtube.com/watch?v=')
        && !lastExercise.includes('https://youtu.be/'))) {
        alert('영상링크를 정확히 입력해주세요');
        return;
      }

      let imageString = '';

      for (let i = 0; i < imgBase64.length; i++) {
        if (i === 0) imageString = imgBase64[i];
        else imageString = imageString + '<-@->' + imgBase64[i];
      }

      // if (isSuccess.value === '완료하였습니다') finalCompleteRep = 0;
      // else {
      //   finalMin = 0;
      //   finalSec = 0;
      // // }

      // if (finalLastExerciseRep === 0) {
      //   finalCompleteRep = 0;
      //   finalCompleteRound = 0;
      // }
      // else {
      //   finalFirstMemberMin = 0;
      //   finalFirstMemberSec = 0;
      //   finalSecondMemberMin = 0;
      //   finalSecondMemberSec = 0;
      // }

      if (isSuccessA.value === '완료하였습니다') finalCompleteRep = 0;
      else {
        finalFirstMemberMin = 0;
        finalFirstMemberSec = 0;
      }

      if (isSuccessB.value === '완료하였습니다') finalCompleteRound = 0;
      else {
        finalSecondMemberMin = 0;
        finalSecondMemberSec = 0;
      }

      let requestData = {
        eventId: eventId,
        section: section,
        isRx: true,
        time: finalMin + finalSec,
        tieBreak: finalTieBreakMin + finalTieBreakSec,
        completeRep: finalCompleteRep,
        completeRound: finalCompleteRound,
        lastExercise: lastExercise,
        lastExerciseRep: 0,
        firstExercise: finalFirstMemberMin + finalFirstMemberSec,
        secondExercise: finalSecondMemberMin + finalSecondMemberSec,
        thirdExercise: 0,
        bodyWeight: 0,
        point: 0,
        link: link,
        picture: imageString
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataOfEvent('record/save', 'POST', requestData);

          if (responseData.status === 200) {
            setIsCompleteSubmit(true);
          }
        }
      )();

      setIsClickedSubmit(false);
    }
    else {
      let requestData = {
        eventId: eventId,
        section: section
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataOfEvent('record/check', 'POST', requestData);
          // console.log(responseData);
          if (responseData.status === 200) {
            setIsExistRecord(false);
            setIsClickedSubmit(true);
          }
          else if (responseData.status === 201) {
            if (window.confirm(t("이전 저장내역이 존재합니다\n계속 진행하시면 이전 데이터는 삭제됩니다"))) {
              setIsExistRecord(false);
              setIsClickedSubmit(true);
            }
          }
        }
      )();
    }
  }

  return (
    <TopLevelWrapper>
      {
        moment().isBefore(moment('2024-12-16T00:00:00')) ?
          <WaitWrapper>
            <WaitImage src={WaitIcon} />
            <Text style={{ marginTop: '8px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>URBANFIT INVITATIONAL</Text>
            <Text style={{ marginTop: '2px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>2024.12.16 Open</Text>
          </WaitWrapper>
          :
          <TotalWrapper>
            <TitleWrapper>
              <SectionBox>
                <Text fontFamily='Poppins-Bold' fontSize={14} color='#FFFFFF'>EVENT 3</Text>
              </SectionBox>
              <TitleBox>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>For time of</Text>
                {/* <Text style={{ marginLeft: '4px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#D92828'>Timecap 7min</Text> */}
              </TitleBox>
            </TitleWrapper>
            <TotalVideoBox width={totalVideoWidth} height={totalVideoHeight}>
              <iframe
                width='100%'
                height='100%'
                // src={`https://picture.link-zone.org/event/ShowYourPerformance/ShowYourPerformanceEvent1RuleVideo.mp4`}
                src={`https://www.youtube.com/embed/VbZVq-SKtR0`}
                style={{ borderRadius: '12px' }}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube" />
            </TotalVideoBox>

            <WodWrapper>
              <OrangeBoxWrapper style={{ marginTop: '10px' }}>
                {/* <OrangeBox> */}
                <ColorBox backgroundColor='#2F80ED'>
                  <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>Scaled</Text>
                </ColorBox>
                {/* </OrangeBox> */}
              </OrangeBoxWrapper>
              <OrangeBoxWrapper style={{ marginTop: '10px' }}>
              </OrangeBoxWrapper>
              <ExerciseWrapper style={{ marginTop: '8px' }}>
                <InfoWrapper>
                  <InfoTitleWrapper>
                    <ExerciseNumberBoxWrapper>
                      <ExerciseNumberBox backgroundColor='#FF8B48'>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>01</Text>
                      </ExerciseNumberBox>
                      <Text style={{ marginTop: '-2px' }} fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>Shuttle Run</Text>
                    </ExerciseNumberBoxWrapper>
                  </InfoTitleWrapper>
                  <InfoDataWrapper style={{ marginTop: '12px' }}>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('횟수')}</Text>
                    <DivisionLine width={54} />
                    <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>30</Text>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>Reps</Text>
                  </InfoDataWrapper>
                </InfoWrapper>
              </ExerciseWrapper>

              <ExerciseWrapper style={{ marginTop: '8px' }}>
                <InfoWrapper>
                  <InfoTitleWrapper>
                    <ExerciseNumberBoxWrapper>
                      <ExerciseNumberBox backgroundColor='#FF8B48'>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>02</Text>
                      </ExerciseNumberBox>
                      <Text style={{ marginTop: '-2px' }} fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>Thruster</Text>
                    </ExerciseNumberBoxWrapper>
                  </InfoTitleWrapper>
                  <InfoDataWrapper style={{ marginTop: '12px' }}>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('무게')}</Text>
                    <DivisionLine width={54} />
                    <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>M:65 F:45</Text>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>lbs</Text>
                  </InfoDataWrapper>
                  <InfoDataWrapper style={{ marginTop: '12px' }}>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('횟수')}</Text>
                    <DivisionLine width={64} />
                    <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>45</Text>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>Reps</Text>
                  </InfoDataWrapper>
                </InfoWrapper>
              </ExerciseWrapper>

              <ExerciseWrapper style={{ marginTop: '8px' }}>
                <InfoWrapper>
                  <InfoTitleWrapper>
                    <ExerciseNumberBoxWrapper>
                      <ExerciseNumberBox backgroundColor='#FF8B48'>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>03</Text>
                      </ExerciseNumberBox>
                      <Text style={{ marginTop: '-2px' }} fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>Single Under</Text>
                    </ExerciseNumberBoxWrapper>
                  </InfoTitleWrapper>
                  <InfoDataWrapper style={{ marginTop: '12px' }}>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('횟수')}</Text>
                    <DivisionLine width={60} />
                    <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>200</Text>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>Reps</Text>
                  </InfoDataWrapper>
                </InfoWrapper>
              </ExerciseWrapper>

              <RedLine style={{ height: '20px', backgroundColor: '#D92828' }}>
                <Text fontFamily='Poppins-SemiBold' fontSize={12} color='#FFFFFF'>Timecap: 8min</Text>
              </RedLine>

            </WodWrapper>
            {/* {
              (!isExistRecord && !isClickedSubmit) && 
              <WodImage style={{ marginTop: '10px' }} src='https://picture.link-zone.org/event/TeamOfFour/TeamOf4_Event1.png' />
            } */}
            {
              (isExistRecord || isClickedSubmit) ?
                <ExerciseWrapper>
                  {
                    isClickedSubmit ?
                      <UrbanfitInvitationalSubmitModel
                        eventId={eventId}
                        section={section}

                        // isSuccess={isSuccess}
                        // setIsSuccess={setIsSuccess}
                        isSuccessA={isSuccessA}
                        setIsSuccessA={setIsSuccessA}
                        isSuccessB={isSuccessB}
                        setIsSuccessB={setIsSuccessB}

                        members={members}

                        min={min}
                        setMin={setMin}
                        sec={sec}
                        setSec={setSec}

                        tieBreakMin={tieBreakMin}
                        setTieBreakMin={setTieBreakMin}
                        tieBreakSec={tieBreakSec}
                        setTieBreakSec={setTieBreakSec}

                        lastExerciseRep={lastExerciseRep}
                        setLastExerciseRep={setLastExerciseRep}

                        firstMemberMin={firstMemberMin}
                        setFirstMemberMin={setFirstMemberMin}
                        firstMemberSec={firstMemberSec}
                        setFirstMemberSec={setFirstMemberSec}

                        secondMemberMin={secondMemberMin}
                        setSecondMemberMin={setSecondMemberMin}
                        secondMemberSec={secondMemberSec}
                        setSecondMemberSec={setSecondMemberSec}

                        completeRep={completeRep}
                        setCompleteRep={setCompleteRep}
                        completeRound={completeRound}
                        setCompleteRound={setCompleteRound}

                        lastExercise={lastExercise}
                        setLastExercise={setLastExercise}

                        link={link}
                        setLink={setLink}
                        imgBase64={imgBase64}
                        setImgBase64={setImgBase64}
                      />
                      :
                      <RecordViewWrapper style={{ marginTop: '10px' }}>
                        <UrbanfitInvitationalSubmitViewModel
                          eventId={eventId}
                          section={section}

                          members={members}

                          setIsExistRecord={setIsExistRecord}

                          isSuccessA={isSuccessA}
                          isSuccessB={isSuccessB}

                          min={viewMin}
                          sec={viewSec}

                          tieBreakMin={viewTieBreakMin}
                          tieBreakSec={viewTieBreakSec}

                          lastExerciseRep={lastExerciseRep}

                          firstMemberMin={viewFirstMemberMin}
                          firstMemberSec={viewFirstMemberSec}

                          secondMemberMin={viewSecondMemberMin}
                          secondMemberSec={viewSecondMemberSec}

                          completeRep={viewCompleteRep}
                          completeRound={viewCompleteRound}

                          lastExercise={viewLastExercise}

                          link={viewLink}
                          picture={picture}
                        />
                      </RecordViewWrapper>
                  }
                </ExerciseWrapper>
                :
                <ExerciseWrapper style={{ paddingTop: '30px' }}>
                  <InfoWrapper>
                    <InfoTitleWrapper>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#333333'>RULE BOOK</Text>
                    </InfoTitleWrapper>
                  </InfoWrapper>
                  <RuleBookDownloadButtonWrapper>
                    {/* <RuleBookDownloadButton onClick={downloadPDF}>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>룰북 다운 받기</Text>
                      <RuleBookDownloadImage src={DownloadIcon}/>
                    </RuleBookDownloadButton> */}
                    <ScoreGuideLineImageWrapper onClick={downloadPDF}>
                      <ScoreGuideLineImage src='https://picture.link-zone.org/event/2025_URBANFIT_INVITATIONAL/UrbanfitEliteEvent3.png' />
                    </ScoreGuideLineImageWrapper>
                  </RuleBookDownloadButtonWrapper>
                  <RuleBookWrapper>
                    <RuleColumnTextWrapper>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{t('상세한 내용은 반드시 [스코어카드 / 동작기준]을')}</Text>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>“룰북 다운 받기” 아이콘을 클릭해주세요</Text>
                    </RuleColumnTextWrapper>

                  </RuleBookWrapper>
                </ExerciseWrapper>
            }
            {
              // moment().isAfter(moment('2024-10-18T00:00:00')) &&
              moment().isBefore(moment('2024-12-22T23:59:59')) &&
              <RankingButton onClick={onClickRecordAndSubmit}>
                <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'>제출하기</Text>
              </RankingButton>
            }
          </TotalWrapper>
      }
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  align-items: center;

  width: 100%;
  min-height: 79vh;
  // background-color: red;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 90.33%;
`;

const SectionBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-right: 8px;

  width: 69px;
  height: 32px;

  border-radius: 6px;
  background-color: #6DD49E;
`;

const OrangeBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  flex-wrap: wrap;

  width: 90.33%;

  gap: 4px;
`;

const ColorBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
`;

const ColorBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 0px 10px;

  border-radius: 5px;
  background-color: ${props => props.backgroundColor};
`;

const TieBreakSectionBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-right: 8px;

  width: 75px;
  height: 32px;

  border-radius: 6px;
  background-color: #FFB800;
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: row;

  // justify-content: space-between;
  justify-content: flex-start;
  align-items: center;

  padding: 0px 12px 0px 12px;
  // padding: 0px 8px 0px 8px;
  // padding: 0px 3px 0px 3px;
  margin: 0px 0px 0px 0px;

  // width: calc(90.33% - 69px);
  width: calc(100% - 69px);
  height: 32px;

  border-radius: 6px;
  background-color: #F9F9F9;
`;

const ExerciseWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // padding: 10px 0px 10px 0px;

  padding-bottom: 10px;

  width: 100%;

  background-color: #F9F9F9;
`;

const RecordViewWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const WodWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // margin-top: 25px;

  width: 100%;
`;

const AndThenBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 21px;

  background-color: #C1C1C1;
`;

const RedLine = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 12px;

  width: 100%;
  height: 27px;

  background-color: #FF3131;
`;

const WodTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 90.33%;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 10px;

  width: 90.33%;
`;

const InfoTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const ExerciseNumberBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const ExerciseNumberBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  padding: 0 0 0 0;
  margin-right: 8px;

  width: 27px;
  height: 18px;

  border-radius: 6px;
  background-color: ${props => props.backgroundColor};
`;

const WaitWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 74px;
`;

const WaitImage = styled.img`
  width: 148px;
  height: 62px;

  :hover {
    cursor: default;
  }
`;

const InfoDataWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const DivisionLine = styled.div`
  width: ${props => props.width}%;
  height: 1px;

  margin: 0px 12px 0px 12px;

  background-color: #E0E0E0;
`;

const ScoreGuideLineImageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 18px;

  width: 90.33%;
  // width: 100%;
  height: auto;

  border-radius: 8px;
  overflow: hidden;

  :hover {
    cursor: pointer;
  }
`;

const ScoreGuideLineImage = styled.img`
  width: 100%;
  height: 100%;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const RuleBookWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  // padding-bottom: 49px;

  margin-top: 21px;
  margin-bottom: 40px;

  width: 90.33%;
`;

const RuleColumnTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const RuleBookDownloadButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 8px;

  width: 100%;
`;

const RuleBookDownloadButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding: 0px 10px;

  width: calc(90.33% - 20px);
  height: 35px;

  border-radius: 5px;
  background-color: #FFB800;
`;

const RuleBookDownloadImage = styled.img`
  width: 21px;
  height: 21px;
`;

const RuleRowTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
`;

const Point = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 5px 10px 0px 0px;

  width: 10px;
  height: 10px;
  
  border-radius: 10px;
  background-color: ${props => props.color};
`;

const RankingButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // margin: 34px 0px 34px 0px;
  // margin-top: 34px;

  // width: 90.33%;
  width: 100%;
  height: 70px;

  // border-radius: 12px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;

  background-color: #6DD49E;

  :hover {
    cursor: pointer;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const TotalVideoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // margin: 25px 0px 25px 0px;
  margin-top: 25px;

  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: 12px;
`;

const WodImage = styled.img`
  width: 100%;
  height: 100%;
`;