import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import XLSX from 'sheetjs-style';
import { cloneDeep } from 'lodash';
import moment, { invalid } from 'moment';

import FetchModule from '../../Model/Network/FetchModule';

import CrownIcon from '../../Image/CrownIcon.png';
import FirstPlaceImg from '../../Image/FirstPlace.png';
import SecondPlaceImg from '../../Image/SecondPlace.png';
import ThirdPlaceImg from '../../Image/ThirdPlace.png';
import RightImg from '../../Image/NotAvailableRight.png';
import NoneProfile from '../../Image/NoneProfile.png';

import { useTranslation } from 'react-i18next';

export default function UrbanfitInvitationalRanking3Page() {

  const { t } = useTranslation();

  // const eventId = 37;//
  const params = useParams();
  const navigate = useNavigate();

  const [nowSection, setNowSection] = useState('A');

  // const [data, setData] = useState([]);
  const [myRanking, setMyRanking] = useState(undefined);
  const [responseRankingList, setResponseRankingList] = useState([]);
  const [rankingList, setRankingList] = useState([]);

  const [isClickedMyRank, setIsClickedMyRank] = useState(false);
  const [myRankTotalHeight, setMyRankTotalHeight] = useState(64);
  const [myRankHeight, setMyRankHeight] = useState(62);

  const [isLoading, setIsLoading] = useState(false);

  const [isClickedDivision, setIsClickedDivision] = useState(1);
  const [myDivision, setMyDivision] = useState(0);

  const openDate = moment('2024-12-16T00:00:00');
  const eventId = 60;

  const [event1MyRank, setEvent1MyRank] = useState(undefined);
  const [event2MyRank, setEvent2MyRank] = useState(undefined);
  const [event3MyRank, setEvent3MyRank] = useState(undefined);
  const [event4MyRank, setEvent4MyRank] = useState(undefined);
  const [event5MyRank, setEvent5MyRank] = useState(undefined);
  const [event6MyRank, setEvent6MyRank] = useState(undefined);
  const [totalMyRank, setTotalMyRank] = useState(undefined);

  const [event1RankingList, setEvent1RankingList] = useState([]);
  const [event2RankingList, setEvent2RankingList] = useState([]);
  const [event3RankingList, setEvent3RankingList] = useState([]);
  const [event4RankingList, setEvent4RankingList] = useState([]);
  const [event5RankingList, setEvent5RankingList] = useState([]);
  const [event6RankingList, setEvent6RankingList] = useState([]);
  const [totalRankingList, setTotalRankingList] = useState([]);


  // Ranking Info API CALL //
  useEffect(() => {

    if (nowSection === 'Total') return;

    setIsLoading(true);
    setIsClickedMyRank(false);
    let finalNowSection = nowSection;

    if (nowSection === 'C') finalNowSection = 'B';
    else if (nowSection === 'D') finalNowSection = 'C';
    else if (nowSection === 'E') finalNowSection = 'C';

    let requestData = {
      eventId: eventId,
      // section: nowSection === 'C' ? 'B' : nowSection
      section: finalNowSection
    }
    // console.log(requestData);
    const fetchModule = new FetchModule();
    (
      async () => {

        const responseData = await fetchModule.postDataOfEvent('score/rank/team', 'POST', requestData);
        // console.log(responseData.data);
        if (responseData.status === 200) {
          // let rankData = responseData.data.eventRankList.slice();
          let rankData = JSON.parse(JSON.stringify(responseData.data.eventRankList));
          let myRank = responseData.data.myRankData;
          let totalRankData = [];

          let validRankData = [];
          let validTimeRankData = [];
          let validRepsRankData = [];
          let invalidRankData = [];

          // // 순위 데이터 정리 //
          // for (let i = 0; i < rankData.length; i++) {
          //   if (nowSection === 'A' && rankData[i].time === 0 && rankData[i].tieBreak === 0) invalidRankData.push(rankData[i]);
          //   else if (nowSection === 'B' && rankData[i].completeRep === 0) invalidRankData.push(rankData[i]);
          //   else validRankData.push(rankData[i]);
          // }

          // 순위 데이터 정리 //
          for (let i = 0; i < rankData.length; i++) {
            if (nowSection === 'A') {
              if (isClickedDivision === 2) {
                if (rankData[i].members[0].gender === rankData[i].members[1].gender
                  && rankData[i].members[0].gender === 'male') {
                  if (rankData[i].time > 0) validTimeRankData.push(rankData[i]);
                  else if (rankData[i].completeRep > 0) validRepsRankData.push(rankData[i]);
                  else invalidRankData.push(rankData[i]);
                }
              }
              else {
                if (rankData[i].members[0].gender !== rankData[i].members[1].gender) {
                  if (rankData[i].time > 0) validTimeRankData.push(rankData[i]);
                  else if (rankData[i].completeRep > 0) validRepsRankData.push(rankData[i]);
                  else invalidRankData.push(rankData[i]);
                }
              }
            }
            else if (nowSection === 'B') {
              let newMembers = rankData[i].members.slice();

              newMembers.sort((a, b) => b.division.localeCompare(a.division) || a.memberId - b.memberId);

              rankData[i].members = newMembers;

              if (isClickedDivision === 2) {
                if (rankData[i].members[0].gender === rankData[i].members[1].gender
                  && rankData[i].members[0].gender === 'male') {

                  let first = parseInt(rankData[i].firstExercise);
                  let second = parseInt(rankData[i].secondExercise);

                  rankData[i].totalWeight = (first + second);

                  if (rankData[i].completeRep > 0) validRankData.push(rankData[i]);
                  else invalidRankData.push(rankData[i]);
                }
              }
              else {
                if (rankData[i].members[0].gender !== rankData[i].members[1].gender) {
                  let first = parseInt(rankData[i].firstExercise);
                  let second = parseInt(rankData[i].secondExercise);

                  rankData[i].totalWeight = (first + second);

                  if (rankData[i].completeRep > 0) validRankData.push(rankData[i]);
                  else invalidRankData.push(rankData[i]);
                }
              }
            }
            else if (nowSection === 'C') {
              let newMembers = rankData[i].members.slice();

              newMembers.sort((a, b) => b.division.localeCompare(a.division) || a.memberId - b.memberId);

              rankData[i].members = newMembers;

              if (isClickedDivision === 2) {
                if (rankData[i].members[0].gender === rankData[i].members[1].gender
                  && rankData[i].members[0].gender === 'male') {

                  let first = parseInt(rankData[i].firstExercise);
                  let second = parseInt(rankData[i].secondExercise);

                  rankData[i].totalWeight = (first + second);

                  if (rankData[i].totalWeight > 0) validRankData.push(rankData[i]);
                  else invalidRankData.push(rankData[i]);
                }
              }
              else {
                if (rankData[i].members[0].gender !== rankData[i].members[1].gender) {
                  let first = parseInt(rankData[i].firstExercise);
                  let second = parseInt(rankData[i].secondExercise);

                  rankData[i].totalWeight = (first + second);

                  if (rankData[i].totalWeight > 0) validRankData.push(rankData[i]);
                  else invalidRankData.push(rankData[i]);
                }
              }
            }
            else if (nowSection === 'D') {
              let newMembers = rankData[i].members.slice();

              newMembers.sort((a, b) => a.gender.localeCompare(b.gender) || b.division.localeCompare(a.division) || a.memberId - b.memberId);

              rankData[i].members = newMembers;
              
              if (isClickedDivision === 2) {
                if (rankData[i].members[0].gender === rankData[i].members[1].gender
                  && rankData[i].members[0].gender === 'male') {

                  if (parseInt(rankData[i].firstExercise) > 0) validTimeRankData.push(rankData[i]);
                  else if (rankData[i].completeRep > 0) validRepsRankData.push(rankData[i]);
                  else invalidRankData.push(rankData[i]);
                }
              }
              else {
                if (rankData[i].members[0].gender !== rankData[i].members[1].gender) {
                  
                  if (parseInt(rankData[i].firstExercise) > 0) validTimeRankData.push(rankData[i]);
                  else if (rankData[i].completeRep > 0) validRepsRankData.push(rankData[i]);
                  else invalidRankData.push(rankData[i]);
                }
              }
            }
            else if (nowSection === 'E') {
              let newMembers = rankData[i].members.slice();

              newMembers.sort((a, b) => a.gender.localeCompare(b.gender) || b.division.localeCompare(a.division) || a.memberId - b.memberId);

              rankData[i].members = newMembers;
              
              if (isClickedDivision === 2) {
                if (rankData[i].members[0].gender === rankData[i].members[1].gender
                  && rankData[i].members[0].gender === 'male') {

                  if (parseInt(rankData[i].secondExercise) > 0) validTimeRankData.push(rankData[i]);
                  else if (rankData[i].completeRound > 0) validRepsRankData.push(rankData[i]);
                  else invalidRankData.push(rankData[i]);
                }
              }
              else {
                if (rankData[i].members[0].gender !== rankData[i].members[1].gender) {
                  
                  if (parseInt(rankData[i].secondExercise) > 0) validTimeRankData.push(rankData[i]);
                  else if (rankData[i].completeRound > 0) validRepsRankData.push(rankData[i]);
                  else invalidRankData.push(rankData[i]);
                }
              }
            }
            else {
              invalidRankData.push(rankData[i]);
            }
          }

          if (nowSection === 'A') {
            // 데이터 순위 정렬 //
            // validRankData.sort((a, b) => b.completeRep - a.completeRep);
            validTimeRankData.sort((a, b) => a.time - b.time || a.tieBreak - b.tieBreak);
            validRepsRankData.sort((a, b) => b.completeRep - a.completeRep || a.tieBreak - b.tieBreak);

            invalidRankData.sort((a, b) => a.time - b.time || a.name.localeCompare(b.name));
            // // 올바르지 않은 데이터는 이름순 정렬 //
            // invalidRankData.sort(function (a, b) {
            //   let x = a.name;
            //   let y = b.name;

            //   if (x > y) return 1;
            //   if (x < y) return -1;
            // })

            validRankData = validTimeRankData.concat(validRepsRankData);

            for (let i = 0; i < validRankData.length; i++) {
              if (i !== 0
                && validRankData[i].time !== 0
                && validRankData[i].time === validRankData[i - 1].time
                && validRankData[i].tieBreak === validRankData[i - 1].tieBreak) {
                validRankData[i].rank = validRankData[i - 1].rank;
              }
              else if (i !== 0
                && validRankData[i].completeRep !== 0
                && validRankData[i].completeRep === validRankData[i - 1].completeRep
                && validRankData[i].tieBreak === validRankData[i - 1].tieBreak) {
                validRankData[i].rank = validRankData[i - 1].rank;
              }
              else validRankData[i].rank = (i + 1);
            }

            for (let i = 0; i < invalidRankData.length; i++) {
              if (validRankData.length === 0) invalidRankData[i].rank = 0;
              else invalidRankData[i].rank = validRankData.length + invalidRankData.length - 1;
            }

            totalRankData = validRankData.concat(invalidRankData);

            // 남성 여성 순으로 정렬 //
            totalRankData.map((data) => {
              let rankMembers = data.members;
              let finalRankMembers = [];

              let rankMales = [];
              let rankFemales = [];

              rankMembers.map((memberData) => {
                if (memberData.gender === 'male') rankMales.push(memberData);
                else rankFemales.push(memberData);
              })

              rankMales.sort(function (a, b) {
                let x = a.name;
                let y = b.name;

                if (x > y) return 1;
                if (x < y) return -1;
              })

              rankFemales.sort(function (a, b) {
                let x = a.name;
                let y = b.name;

                if (x > y) return 1;
                if (x < y) return -1;
              })

              finalRankMembers = rankMales.concat(rankFemales);

              data.members = finalRankMembers;
            })

            // 내 순위 찾기
            if (myRank !== undefined) {

              for (let i = 0; i < totalRankData.length; i++) {
                if (totalRankData[i].teamId === myRank.teamId) {
                  myRank.rank = totalRankData[i].rank;
                  myRank.profile = totalRankData[i].profile;
                  break;
                }
              }
              // Total Section 내 순위 에러 방지용 초기화 //
              myRank.event1 = {
                rank: -1,
                time: 0,
                completeRep: 0,
                tieBreak: 0
              }

              myRank.event2 = {
                rank: -1,
                completeRep: 0,
                tieBreak: 0
              }

              myRank.event3 = {
                rank: -1,
                time: 0,
                completeRep: 0,
              }

              myRank.event4 = {
                rank: -1,
                totalWeight: 0,

                completeRep: 0,
                firstExercise: -1,

                completeRound: 0,
                secondExercise: -1,

                lastExerciseRep: 0,
                thirdExercise: -1,

                bodyWeight: 0,
                distance: -1
              }

              myRank.event5 = {
                rank: -1,
                time: 0,
              }

              setMyRanking(myRank);
            }

            // Total Section 전체 순위 에러 방지용 초기화 //
            totalRankData.map((data) => {
              data.event1 = {
                rank: -1,
                time: 0,
                completeRep: 0,
                tieBreak: 0
              }

              data.event2 = {
                rank: -1,
                time: 0,
                completeRep: 0,
              }

              data.event3 = {
                rank: -1,
                time: 0,
                completeRep: 0,
              }

              data.event4 = {
                rank: -1,
                totalWeight: 0,

                completeRep: 0,
                firstExercise: -1,

                completeRound: 0,
                secondExercise: -1,

                lastExerciseRep: 0,
                thirdExercise: -1,

                bodyWeight: 0,
                distance: -1
              }

              data.event5 = {
                rank: -1,
                time: 0,
              }

            })

            setResponseRankingList(totalRankData);
          }
          else if (nowSection === 'B') {
            // 데이터 순위 정렬 //
            // validTimeRankData.sort((a, b) => a.time - b.time || b.totalWeight - a.totalWeight);
            validRankData.sort((a, b) => b.completeRep - a.completeRep || b.totalWeight - a.totalWeight);

            // // 올바르지 않은 데이터는 이름순 정렬 //
            // invalidRankData.sort(function (a, b) {
            //   let x = a.name;
            //   let y = b.name;

            //   if (x > y) return 1;
            //   if (x < y) return -1;
            // })
            invalidRankData.sort((a, b) => a.completeRep - b.completeRep || a.name.localeCompare(b.name));

            // validRankData = validTimeRankData.concat(validRepsRankData);

            for (let i = 0; i < validRankData.length; i++) {
              if (i !== 0
                && validRankData[i].time !== 0
                && validRankData[i].time === validRankData[i - 1].time
                && validRankData[i].totalWeight === validRankData[i - 1].totalWeight) {
                validRankData[i].rank = validRankData[i - 1].rank;
              }
              else if (i !== 0
                && validRankData[i].completeRep !== 0
                && validRankData[i].completeRep === validRankData[i - 1].completeRep
                && validRankData[i].totalWeight === validRankData[i - 1].totalWeight) {
                validRankData[i].rank = validRankData[i - 1].rank;
              }
              else validRankData[i].rank = (i + 1);
            }

            for (let i = 0; i < invalidRankData.length; i++) {
              if (validRankData.length === 0) invalidRankData[i].rank = 0;
              else invalidRankData[i].rank = validRankData.length + invalidRankData.length - 1;
            }

            totalRankData = validRankData.concat(invalidRankData);

            // // 남성 여성 순으로 정렬 //
            // totalRankData.map((data) => {
            //   let rankMembers = data.members;
            //   let finalRankMembers = [];

            //   let rankMales = [];
            //   let rankFemales = [];

            //   rankMembers.map((memberData) => {
            //     if (memberData.gender === 'male') rankMales.push(memberData);
            //     else rankFemales.push(memberData);
            //   })

            //   rankMales.sort(function (a, b) {
            //     let x = a.name;
            //     let y = b.name;

            //     if (x > y) return 1;
            //     if (x < y) return -1;
            //   })

            //   rankFemales.sort(function (a, b) {
            //     let x = a.name;
            //     let y = b.name;

            //     if (x > y) return 1;
            //     if (x < y) return -1;
            //   })

            //   finalRankMembers = rankMales.concat(rankFemales);

            //   data.members = finalRankMembers;
            // })

            // 남성 여성 순으로 정렬 //
            totalRankData.map((data) => {
              let rankMembers = data.members;

              rankMembers.sort((a, b) => b.division.localeCompare(a.division) || a.memberId - b.memberId);
              // newMembers.sort((a, b) => b.division.localeCompare(a.division) || a.memberId - b.memberId);
              data.members = rankMembers;
            })

            // 내 순위 찾기
            if (myRank !== undefined) {

              for (let i = 0; i < totalRankData.length; i++) {
                if (totalRankData[i].teamId === myRank.teamId) {
                  myRank.rank = totalRankData[i].rank;
                  myRank.profile = totalRankData[i].profile;
                  myRank.totalWeight = totalRankData[i].totalWeight;
                  myRank.members = totalRankData[i].members;
                  break;
                }
              }
              // Total Section 내 순위 에러 방지용 초기화 //
              myRank.event1 = {
                rank: -1,
                completeRep: 0,
                tieBreak: 0
              }

              myRank.event2 = {
                rank: -1,
                completeRep: 0,
              }

              myRank.event3 = {
                rank: -1,
                time: 0,
                completeRep: 0,
              }

              myRank.event4 = {
                rank: -1,
                totalWeight: 0,

                completeRep: 0,
                firstExercise: -1,

                completeRound: 0,
                secondExercise: -1,

                lastExerciseRep: 0,
                thirdExercise: -1,

                bodyWeight: 0,
                distance: -1
              }

              myRank.event5 = {
                rank: -1,
                time: 0,
              }

              setMyRanking(myRank);
            }

            // Total Section 전체 순위 에러 방지용 초기화 //
            totalRankData.map((data) => {
              data.event1 = {
                rank: -1,
                completeRe: 0,
                tieBreak: 0
              }

              data.event2 = {
                rank: -1,
                time: 0,
                completeRep: 0,
              }

              data.event3 = {
                rank: -1,
                time: 0,
                completeRep: 0,
              }

              data.event4 = {
                rank: -1,
                totalWeight: 0,

                completeRep: 0,
                firstExercise: -1,

                completeRound: 0,
                secondExercise: -1,

                lastExerciseRep: 0,
                thirdExercise: -1,

                bodyWeight: 0,
                distance: -1
              }

              data.event5 = {
                rank: -1,
                time: 0,
              }
            })

            setResponseRankingList(totalRankData);
          }
          else if (nowSection === 'C') {
            // 데이터 순위 정렬 //
            validRankData.sort((a, b) => b.totalWeight - a.totalWeight || b.completeRep - a.completeRep);

            // // 올바르지 않은 데이터는 이름순 정렬 //
            // invalidRankData.sort(function (a, b) {
            //   let x = a.name;
            //   let y = b.name;

            //   if (x > y) return 1;
            //   if (x < y) return -1;
            // })
            invalidRankData.sort((a, b) => a.totalWeight - b.totalWeight || a.name.localeCompare(b.name));

            // validRankData = validTimeRankData.concat(validRepsRankData);

            for (let i = 0; i < validRankData.length; i++) {
              if (i !== 0
                && validRankData[i].totalWeight !== 0
                && validRankData[i].totalWeight === validRankData[i - 1].totalWeight
                && validRankData[i].completeRep === validRankData[i - 1].completeRep) {
                validRankData[i].rank = validRankData[i - 1].rank;
              }
              else validRankData[i].rank = (i + 1);
            }

            for (let i = 0; i < invalidRankData.length; i++) {
              if (validRankData.length === 0) invalidRankData[i].rank = 0;
              else invalidRankData[i].rank = validRankData.length + invalidRankData.length - 1;
            }

            totalRankData = validRankData.concat(invalidRankData);

            // 남성 여성 순으로 정렬 //
            totalRankData.map((data) => {
              let rankMembers = data.members;

              rankMembers.sort((a, b) => b.division.localeCompare(a.division) || a.memberId - b.memberId);
              // newMembers.sort((a, b) => b.division.localeCompare(a.division) || a.memberId - b.memberId);
              data.members = rankMembers;
            })

            // 내 순위 찾기
            if (myRank !== undefined) {

              for (let i = 0; i < totalRankData.length; i++) {
                if (totalRankData[i].teamId === myRank.teamId) {
                  myRank.rank = totalRankData[i].rank;
                  myRank.profile = totalRankData[i].profile;
                  myRank.totalWeight = totalRankData[i].totalWeight;
                  myRank.members = totalRankData[i].members;
                  break;
                }
              }
              // Total Section 내 순위 에러 방지용 초기화 //
              myRank.event1 = {
                rank: -1,
                completeRep: 0,
                tieBreak: 0
              }

              myRank.event2 = {
                rank: -1,
                time: 0,
                completeRep: 0,
              }

              myRank.event3 = {
                rank: -1,
                time: 0,
                completeRep: 0,
              }

              myRank.event4 = {
                rank: -1,
                totalWeight: 0,

                completeRep: 0,
                firstExercise: -1,

                completeRound: 0,
                secondExercise: -1,

                lastExerciseRep: 0,
                thirdExercise: -1,

                bodyWeight: 0,
                distance: -1
              }

              myRank.event5 = {
                rank: -1,
                time: 0,
              }

              setMyRanking(myRank);
            }

            // Total Section 전체 순위 에러 방지용 초기화 //
            totalRankData.map((data) => {
              data.event1 = {
                rank: -1,
                time: 0,
                completeRe: 0,
                tieBreak: 0
              }

              data.event2 = {
                rank: -1,
                time: 0,
                completeRep: 0,
              }

              data.event3 = {
                rank: -1,
                time: 0,
                completeRep: 0,
              }

              data.event4 = {
                rank: -1,
                totalWeight: 0,

                completeRep: 0,
                firstExercise: -1,

                completeRound: 0,
                secondExercise: -1,

                lastExerciseRep: 0,
                thirdExercise: -1,

                bodyWeight: 0,
                distance: -1
              }

              data.event5 = {
                rank: -1,
                time: 0,
              }
            })

            setResponseRankingList(totalRankData);
          }
          else if (nowSection === 'D') {
            // 데이터 순위 정렬 //
            validTimeRankData.sort((a, b) => a.firstExercise - b.firstExercise || a.time - b.time);
            validRepsRankData.sort((a, b) => b.completeRep - a.completeRep || a.time - b.time);

            // // 올바르지 않은 데이터는 이름순 정렬 //
            // invalidRankData.sort(function (a, b) {
            //   let x = a.name;
            //   let y = b.name;

            //   if (x > y) return 1;
            //   if (x < y) return -1;
            // })
            invalidRankData.sort((a, b) => a.firstExercise - b.firstExercise || a.name.localeCompare(b.name));

            validRankData = validTimeRankData.concat(validRepsRankData);

            for (let i = 0; i < validRankData.length; i++) {
              if (i !== 0
                && parseInt(validRankData[i].firstExercise) !== 0
                && validRankData[i].firstExercise === validRankData[i - 1].firstExercise
                && validRankData[i].time === validRankData[i - 1].time) {
                validRankData[i].rank = validRankData[i - 1].rank;
              }
              else if (i !== 0
                && validRankData[i].completeRep !== 0
                && validRankData[i].completeRep === validRankData[i - 1].completeRep
                && validRankData[i].time === validRankData[i - 1].time) {
                validRankData[i].rank = validRankData[i - 1].rank;
              }
              else validRankData[i].rank = (i + 1);
            }

            for (let i = 0; i < invalidRankData.length; i++) {
              if (validRankData.length === 0) invalidRankData[i].rank = 0;
              else invalidRankData[i].rank = validRankData.length + invalidRankData.length - 1;

              invalidRankData[i].lastExerciseRep = -1;
            }

            totalRankData = validRankData.concat(invalidRankData);

            // // 남성 여성 순으로 정렬 //
            // totalRankData.map((data) => {
            //   let rankMembers = data.members;
            //   let finalRankMembers = [];

            //   let rankMales = [];
            //   let rankFemales = [];

            //   rankMembers.map((memberData) => {
            //     if (memberData.gender === 'male') rankMales.push(memberData);
            //     else rankFemales.push(memberData);
            //   })

            //   rankMales.sort(function (a, b) {
            //     let x = a.name;
            //     let y = b.name;

            //     if (x > y) return 1;
            //     if (x < y) return -1;
            //   })

            //   rankFemales.sort(function (a, b) {
            //     let x = a.name;
            //     let y = b.name;

            //     if (x > y) return 1;
            //     if (x < y) return -1;
            //   })

            //   finalRankMembers = rankMales.concat(rankFemales);

            //   data.members = finalRankMembers;
            // })

            // 내 순위 찾기
            if (myRank !== undefined) {

              for (let i = 0; i < totalRankData.length; i++) {
                if (totalRankData[i].teamId === myRank.teamId) {
                  myRank.rank = totalRankData[i].rank;
                  myRank.profile = totalRankData[i].profile;
                  myRank.completeRep = totalRankData[i].completeRep;
                  myRank.completeRound = totalRankData[i].completeRound;
                  myRank.firstExercise = totalRankData[i].firstExercise;
                  myRank.secondExercise = totalRankData[i].secondExercise;
                  myRank.time = totalRankData[i].time;
                  myRank.tieBreak = totalRankData[i].tieBreak;

                  if (totalRankData[i].totalTime === 0 && totalRankData[i].totalReps === 0) myRank.lastExerciseRep = -1;
                  else myRank.lastExerciseRep = totalRankData[i].lastExerciseRep;

                  break;
                }
              }
              // Total Section 내 순위 에러 방지용 초기화 //
              myRank.event1 = {
                rank: -1,
                completeRep: 0,
                tieBreak: 0
              }

              myRank.event2 = {
                rank: -1,
                time: 0,
                completeRep: 0,
              }

              myRank.event3 = {
                rank: -1,
                time: 0,
                completeRep: 0,
              }

              myRank.event4 = {
                rank: -1,
                firstExercise: 0,
                secondExercise: 0,
                completeRep: 0,
                completeRound: 0,
                time: 0,
                tieBreak: 0
              }

              myRank.event5 = {
                rank: -1,
                time: 0,
              }

              let newMembers = myRank.members.slice();

              newMembers.sort((a, b) => a.gender.localeCompare(b.gender) || b.division.localeCompare(a.division) || a.memberId - b.memberId);

              myRank.members = newMembers;

              setMyRanking(myRank);
            }

            // Total Section 전체 순위 에러 방지용 초기화 //
            totalRankData.map((data) => {
              data.event1 = {
                rank: -1,
                time: 0,
                completeRe: 0,
                tieBreak: 0
              }

              data.event2 = {
                rank: -1,
                time: 0,
                completeRep: 0,
              }

              data.event3 = {
                rank: -1,
                time: 0,
                completeRep: 0,
              }

              data.event4 = {
                rank: -1,
                totalReps: 0,
                totalTieBreak: 0,
                completeRep: 0,
                completeRound: 0,
                time: 0,
                tieBreak: 0
              }

              data.event5 = {
                rank: -1,
                time: 0,
              }
            })

            setResponseRankingList(totalRankData);
          }
          else if (nowSection === 'E') {
            // 데이터 순위 정렬 //
            validTimeRankData.sort((a, b) => a.secondExercise - b.secondExercise || a.tieBreak - b.tieBreak);
            validRepsRankData.sort((a, b) => b.completeRound - a.completeRound || a.tieBreak - b.tieBreak);

            // // 올바르지 않은 데이터는 이름순 정렬 //
            // invalidRankData.sort(function (a, b) {
            //   let x = a.name;
            //   let y = b.name;

            //   if (x > y) return 1;
            //   if (x < y) return -1;
            // })
            invalidRankData.sort((a, b) => a.secondExercise - b.secondExercise || a.name.localeCompare(b.name));

            validRankData = validTimeRankData.concat(validRepsRankData);

            for (let i = 0; i < validRankData.length; i++) {
              if (i !== 0
                && parseInt(validRankData[i].secondExercise) !== 0
                && validRankData[i].secondExercise === validRankData[i - 1].secondExercise
                && validRankData[i].time === validRankData[i - 1].tieBreak) {
                validRankData[i].rank = validRankData[i - 1].rank;
              }
              else if (i !== 0
                && validRankData[i].completeRound !== 0
                && validRankData[i].completeRound === validRankData[i - 1].completeRound
                && validRankData[i].tieBreak === validRankData[i - 1].tieBreak) {
                validRankData[i].rank = validRankData[i - 1].rank;
              }
              else validRankData[i].rank = (i + 1);
            }

            for (let i = 0; i < invalidRankData.length; i++) {
              if (validRankData.length === 0) invalidRankData[i].rank = 0;
              else invalidRankData[i].rank = validRankData.length + invalidRankData.length - 1;

              invalidRankData[i].lastExerciseRep = -1;
            }

            totalRankData = validRankData.concat(invalidRankData);

            // // 남성 여성 순으로 정렬 //
            // totalRankData.map((data) => {
            //   let rankMembers = data.members;
            //   let finalRankMembers = [];

            //   let rankMales = [];
            //   let rankFemales = [];

            //   rankMembers.map((memberData) => {
            //     if (memberData.gender === 'male') rankMales.push(memberData);
            //     else rankFemales.push(memberData);
            //   })

            //   rankMales.sort(function (a, b) {
            //     let x = a.name;
            //     let y = b.name;

            //     if (x > y) return 1;
            //     if (x < y) return -1;
            //   })

            //   rankFemales.sort(function (a, b) {
            //     let x = a.name;
            //     let y = b.name;

            //     if (x > y) return 1;
            //     if (x < y) return -1;
            //   })

            //   finalRankMembers = rankMales.concat(rankFemales);

            //   data.members = finalRankMembers;
            // })

            // 내 순위 찾기
            if (myRank !== undefined) {

              for (let i = 0; i < totalRankData.length; i++) {
                if (totalRankData[i].teamId === myRank.teamId) {
                  myRank.rank = totalRankData[i].rank;
                  myRank.profile = totalRankData[i].profile;
                  myRank.completeRep = totalRankData[i].completeRep;
                  myRank.completeRound = totalRankData[i].completeRound;
                  myRank.firstExercise = totalRankData[i].firstExercise;
                  myRank.secondExercise = totalRankData[i].secondExercise;
                  myRank.time = totalRankData[i].time;
                  myRank.tieBreak = totalRankData[i].tieBreak;

                  if (totalRankData[i].totalTime === 0 && totalRankData[i].totalReps === 0) myRank.lastExerciseRep = -1;
                  else myRank.lastExerciseRep = totalRankData[i].lastExerciseRep;

                  break;
                }
              }
              // Total Section 내 순위 에러 방지용 초기화 //
              myRank.event1 = {
                rank: -1,
                completeRep: 0,
                tieBreak: 0
              }

              myRank.event2 = {
                rank: -1,
                time: 0,
                completeRep: 0,
              }

              myRank.event3 = {
                rank: -1,
                time: 0,
                completeRep: 0,
              }

              myRank.event4 = {
                rank: -1,
                firstExercise: 0,
                secondExercise: 0,
                completeRep: 0,
                completeRound: 0,
                time: 0,
                tieBreak: 0
              }

              myRank.event5 = {
                rank: -1,
                time: 0,
              }


              let newMembers = myRank.members.slice();

              newMembers.sort((a, b) => a.gender.localeCompare(b.gender) || b.division.localeCompare(a.division) || a.memberId - b.memberId);

              myRank.members = newMembers;

              setMyRanking(myRank);
            }

            // Total Section 전체 순위 에러 방지용 초기화 //
            totalRankData.map((data) => {
              data.event1 = {
                rank: -1,
                time: 0,
                completeRe: 0,
                tieBreak: 0
              }

              data.event2 = {
                rank: -1,
                time: 0,
                completeRep: 0,
              }

              data.event3 = {
                rank: -1,
                time: 0,
                completeRep: 0,
              }

              data.event4 = {
                rank: -1,
                totalReps: 0,
                totalTieBreak: 0,
                completeRep: 0,
                completeRound: 0,
                time: 0,
                tieBreak: 0
              }

              data.event5 = {
                rank: -1,
                time: 0,
              }
            })

            setResponseRankingList(totalRankData);
          }
          else {

            // 올바르지 않은 데이터는 이름순 정렬 //
            invalidRankData.sort(function (a, b) {
              let x = a.name;
              let y = b.name;

              if (x > y) return 1;
              if (x < y) return -1;
            })

            for (let i = 0; i < invalidRankData.length; i++) {
              if (validRankData.length === 0) invalidRankData[i].rank = 0;
              else invalidRankData[i].rank = validRankData.length + invalidRankData.length - 1;
            }

            totalRankData = validRankData.concat(invalidRankData);

            // 남성 여성 순으로 정렬 //
            totalRankData.map((data) => {
              let rankMembers = data.members;
              let finalRankMembers = [];

              let rankMales = [];
              let rankFemales = [];

              rankMembers.map((memberData) => {
                if (memberData.gender === 'male') rankMales.push(memberData);
                else rankFemales.push(memberData);
              })

              rankMales.sort(function (a, b) {
                let x = a.name;
                let y = b.name;

                if (x > y) return 1;
                if (x < y) return -1;
              })

              rankFemales.sort(function (a, b) {
                let x = a.name;
                let y = b.name;

                if (x > y) return 1;
                if (x < y) return -1;
              })

              finalRankMembers = rankMales.concat(rankFemales);

              data.members = finalRankMembers;
            })

            // 내 순위 찾기
            if (myRank !== undefined) {

              for (let i = 0; i < totalRankData.length; i++) {
                if (totalRankData[i].teamId === myRank.teamId) {
                  myRank.rank = totalRankData[i].rank;
                  myRank.profile = totalRankData[i].profile;
                  break;
                }
              }
              // Total Section 내 순위 에러 방지용 초기화 //
              myRank.event1 = {
                rank: -1,
                completeRep: 0,
                tieBreak: 0
              }

              myRank.event2 = {
                rank: -1,
                time: 0,
                completeRep: 0,
              }

              myRank.event3 = {
                rank: -1,
                time: 0,
                completeRep: 0,
              }

              setMyRanking(myRank);
            }

            // Total Section 전체 순위 에러 방지용 초기화 //
            totalRankData.map((data) => {
              data.event1 = {
                rank: -1,
                time: 0,
                completeRe: 0,
                tieBreak: 0
              }

              data.event2 = {
                rank: -1,
                time: 0,
                completeRep: 0,
              }

              data.event3 = {
                rank: -1,
                time: 0,
                completeRep: 0,
              }
            })

            setResponseRankingList(totalRankData);
          }
        }

        // setIsLoading(false);
        setTimeout(() => {
          setIsLoading(false);
        }, 300);
      }
    )();
  }, [params, eventId, nowSection, isClickedDivision])

  // 전체 순위 산출일 경우 //
  useEffect(() => {
    if (nowSection !== 'Total') return;

    setIsLoading(true);
    setIsClickedMyRank(false);

    let requestData = {
      eventId: eventId
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataOfEvent('score/rank/team/total', 'POST', requestData);
        // console.log(responseData.data);
        if (responseData.status === 200) {
          let myRankData = responseData.data.myRankData;
          let event1 = responseData.data.event1.slice();
          let event2 = responseData.data.event2.slice();
          // let event3 = responseData.data.event3.slice();
          let event3 = cloneDeep(responseData.data.event2);
          let event4 = responseData.data.event3.slice();
          let event5 = cloneDeep(responseData.data.event3);

          // Event 1 순위 산출 //
          let event1ValidRank = [];
          let event1TimeValidRank = [];
          let event1RepsValidRank = [];
          let event1InvalidRank = [];
          let event1TotalRank = [];

          // event1.map((data) => {
          //   if (data.time === 0 && data.tieBreak === 0) event1InvalidRank.push(data);
          //   else event1ValidRank.push(data);
          // })
          // if (isClickedDivision === 2) {
          //   if (rankData[i].members[0].gender === rankData[i].members[1].gender
          //     && rankData[i].members[0].gender === 'male') {
          //     if (rankData[i].time !== 0) validTimeRankData.push(rankData[i]);
          //     else if (rankData[i].completeRep !== 0) validRepsRankData.push(rankData[i]);
          //     else invalidRankData.push(rankData[i]);
          //   }
          // }
          // else {
          //   if (rankData[i].members[0].gender !== rankData[i].members[1].gender) {
          //     if (rankData[i].time !== 0) validTimeRankData.push(rankData[i]);
          //     else if (rankData[i].completeRep !== 0) validRepsRankData.push(rankData[i]);
          //     else invalidRankData.push(rankData[i]);
          //   }
          // }

          event1.map((data) => {
            if (isClickedDivision === 2) {
              if (data.members[0].gender === data.members[1].gender
                && data.members[0].gender === 'male') {
                if (data.time > 0) event1TimeValidRank.push(data);
                else if (data.completeRep > 0) event1RepsValidRank.push(data);
                else event1InvalidRank.push(data);
              }
            }
            else {
              if (data.members[0].gender !== data.members[1].gender) {
                if (data.time > 0) event1TimeValidRank.push(data);
                else if (data.completeRep > 0) event1RepsValidRank.push(data);
                else event1InvalidRank.push(data);
              }
            }
            // if (data.time !== 0) event1TimeValidRank.push(data);
            // else if (data.completeRep !== 0) event1RepsValidRank.push(data);
            // else event1ValidRank.push(data);
          })

          // 남성 데이터 순위 정렬 //
          event1TimeValidRank.sort((a, b) => a.time - b.time || a.tieBreak - b.tieBreak);
          event1RepsValidRank.sort((a, b) => b.completeRep - a.completeRep || a.tieBreak - b.tieBreak);

          // // 올바르지 않은 데이터는 이름순 정렬 //
          // event1InvalidRank.sort(function (a, b) {
          //   let x = a.name;
          //   let y = b.name;

          //   if (x > y) return 1;
          //   if (x < y) return -1;
          // })

          event1InvalidRank.sort((a, b) => a.time - b.time || a.name.localeCompare(b.name));

          event1ValidRank = event1TimeValidRank.concat(event1RepsValidRank);

          for (let i = 0; i < event1ValidRank.length; i++) {
            if (i !== 0
              && event1ValidRank[i].time !== 0
              && event1ValidRank[i].time === event1ValidRank[i - 1].time
              && event1ValidRank[i].tieBreak === event1ValidRank[i - 1].tieBreak) {
              event1ValidRank[i].rank = event1ValidRank[i - 1].rank;
            }
            else if (i !== 0
              && event1ValidRank[i].completeRep !== 0
              && event1ValidRank[i].completeRep === event1ValidRank[i - 1].completeRep
              && event1ValidRank[i].tieBreak === event1ValidRank[i - 1].tieBreak) {
              event1ValidRank[i].rank = event1ValidRank[i - 1].rank;
            }
            else event1ValidRank[i].rank = (i + 1);
          }

          for (let i = 0; i < event1InvalidRank.length; i++) {
            if (event1ValidRank.length === 0) event1InvalidRank[i].rank = 0;
            else event1InvalidRank[i].rank = event1ValidRank.length + event1InvalidRank.length - 1;
          }

          event1TotalRank = event1ValidRank.concat(event1InvalidRank);

          // 남성 여성 순으로 정렬 //
          event1TotalRank.map((data) => {
            let rankMembers = data.members;
            let finalRankMembers = [];

            let rankMales = [];
            let rankFemales = [];

            rankMembers.map((memberData) => {
              if (memberData.gender === 'male') rankMales.push(memberData);
              else rankFemales.push(memberData);
            })

            rankMales.sort(function (a, b) {
              let x = a.name;
              let y = b.name;

              if (x > y) return 1;
              if (x < y) return -1;
            })

            rankFemales.sort(function (a, b) {
              let x = a.name;
              let y = b.name;

              if (x > y) return 1;
              if (x < y) return -1;
            })

            finalRankMembers = rankMales.concat(rankFemales);

            data.members = finalRankMembers;
          })

          setEvent1RankingList(event1ValidRank);
          // Event 1 순위 산출 //

          // Event 2 순위 산출 //
          let event2ValidRank = [];
          let event2ValidTimeRank = [];
          let event2ValidRepsRank = [];
          let event2InvalidRank = [];
          let event2TotalRank = [];

          event2.map((data) => {
            // data.totalWeight = data.firstExercise + data.secondExercise;

            // if (data.time !== 0) event2ValidTimeRank.push(data);
            // else if (data.completeRep !== 0) event2ValidRepsRank.push(data);
            // else event2InvalidRank.push(data);


            if (isClickedDivision === 2) {
              if (data.members[0].gender === data.members[1].gender
                && data.members[0].gender === 'male') {
                data.totalWeight = data.firstExercise + data.secondExercise;

                if (data.completeRep > 0) event2ValidRank.push(data);
                else event2InvalidRank.push(data);
              }
            }
            else {
              if (data.members[0].gender !== data.members[1].gender) {
                data.totalWeight = data.firstExercise + data.secondExercise;

                if (data.completeRep > 0) event2ValidRank.push(data);
                else event2InvalidRank.push(data);
              }
            }
          })

          // // 데이터 순위 정렬 //
          // event2ValidTimeRank.sort((a, b) => a.time - b.time || b.totalWeight - a.totalWeight);
          // // 데이터 순위 정렬 //
          // event2ValidRepsRank.sort((a, b) => b.completeRep - a.completeRep || b.totalWeight - a.totalWeight);

          // event2ValidRank = event2ValidTimeRank.concat(event2ValidRepsRank);
          event2ValidRank.sort((a, b) => b.completeRep - a.completeRep || b.totalWeight - a.totalWeight);

          // // 올바르지 않은 데이터는 이름순 정렬 //
          // event2InvalidRank.sort(function (a, b) {
          //   let x = a.name;
          //   let y = b.name;

          //   if (x > y) return 1;
          //   if (x < y) return -1;
          // })
          event2InvalidRank.sort((a, b) => a.completeRep - b.completeRep || a.name.localeCompare(b.name));

          // 순위 산출 //
          for (let i = 0; i < event2ValidRank.length; i++) {
            if (i !== 0
              && event2ValidRank[i].completeRep !== 0
              && event2ValidRank[i].completeRep === event2ValidRank[i - 1].completeRep
              && event2ValidRank[i].totalWeight === event2ValidRank[i - 1].totalWeight) {
              event2ValidRank[i].rank = event2ValidRank[i - 1].rank;
            }
            else {
              event2ValidRank[i].rank = (i + 1);
            }
          }

          for (let i = 0; i < event2InvalidRank.length; i++) {
            if (event2ValidRank.length === 0) event2InvalidRank[i].rank = 0;
            else event2InvalidRank[i].rank = event2ValidRank.length + event2InvalidRank.length - 1;
          }

          event2TotalRank = event2ValidRank.concat(event2InvalidRank);
          // 남성 여성 순으로 정렬 //
          event2TotalRank.map((data) => {
            let rankMembers = data.members;
            let finalRankMembers = [];

            let rankMales = [];
            let rankFemales = [];

            rankMembers.map((memberData) => {
              if (memberData.gender === 'male') rankMales.push(memberData);
              else rankFemales.push(memberData);
            })

            rankMales.sort(function (a, b) {
              let x = a.name;
              let y = b.name;

              if (x > y) return 1;
              if (x < y) return -1;
            })

            rankFemales.sort(function (a, b) {
              let x = a.name;
              let y = b.name;

              if (x > y) return 1;
              if (x < y) return -1;
            })

            finalRankMembers = rankMales.concat(rankFemales);

            data.members = finalRankMembers;
          })

          setEvent2RankingList(event2ValidRank);
          // Event 2 순위 산출 //

          // Event 3 순위 산출 //
          let event3ValidRank = [];
          let event3TimeValidRank = [];
          let event3RepsValidRank = [];
          let event3InvalidRank = [];
          let event3TotalRank = [];

          event3.map((data) => {
            // data.totalWeight = data.firstExercise + data.secondExercise + data.thirdExercise;

            // if (data.time !== 0) data.bonus = 2;
            // else if (data.completeRep !== 0) data.bonus = 1;
            // else data.bonus = 0;

            // if (data.totalWeight !== 0) event3ValidRank.push(data);
            // else event3InvalidRank.push(data);

            if (isClickedDivision === 2) {
              if (data.members[0].gender === data.members[1].gender
                && data.members[0].gender === 'male') {
                data.totalWeight = data.firstExercise + data.secondExercise;

                if (data.totalWeight > 0) event3ValidRank.push(data);
                else event3InvalidRank.push(data);
              }
            }
            else {
              if (data.members[0].gender !== data.members[1].gender) {
                data.totalWeight = data.firstExercise + data.secondExercise;

                if (data.totalWeight > 0) event3ValidRank.push(data);
                else event3InvalidRank.push(data);
              }
            }
          })

          // 남성 데이터 순위 정렬 //
          // event3ValidRank.sort((a, b) => b.totalWeight - a.totalWeight || b.bonus - a.bonus || a.time - b.time || b.completeRep - a.completeRep);

          event3ValidRank.sort((a, b) => b.totalWeight - a.totalWeight || b.completeRep - a.completeRep);

          // // 올바르지 않은 데이터는 이름순 정렬 //
          // event3InvalidRank.sort(function (a, b) {
          //   let x = a.name;
          //   let y = b.name;

          //   if (x > y) return 1;
          //   if (x < y) return -1;
          // })
          event3InvalidRank.sort((a, b) => a.totalWeight - b.totalWeight || a.name.localeCompare(b.name));

          // event3ValidRank = event3TimeValidRank.concat(event3RepsValidRank);

          for (let i = 0; i < event3ValidRank.length; i++) {
            if (i !== 0
              && event3ValidRank[i].totalWeight !== 0
              && event3ValidRank[i].totalWeight === event3ValidRank[i - 1].totalWeight
              && event3ValidRank[i].time !== 0
              && event3ValidRank[i].time === event3ValidRank[i - 1].time)
              event3ValidRank[i].rank = event3ValidRank[i - 1].rank;
            else if (i !== 0
              && event3ValidRank[i].totalWeight !== 0
              && event3ValidRank[i].totalWeight === event3ValidRank[i - 1].totalWeight
              && event3ValidRank[i].completeRep !== 0
              && event3ValidRank[i].completeRep === event3ValidRank[i - 1].completeRep)
              event3ValidRank[i].rank = event3ValidRank[i - 1].rank;
            else event3ValidRank[i].rank = (i + 1);
          }

          for (let i = 0; i < event3InvalidRank.length; i++) {
            if (event3ValidRank.length === 0) event3InvalidRank[i].rank = 0;
            else event3InvalidRank[i].rank = event3ValidRank.length + event3InvalidRank.length - 1;
          }

          event3TotalRank = event3ValidRank.concat(event3InvalidRank);

          // 남성 여성 순으로 정렬 //
          event3TotalRank.map((data) => {
            let rankMembers = data.members;
            let finalRankMembers = [];

            let rankMales = [];
            let rankFemales = [];

            rankMembers.map((memberData) => {
              if (memberData.gender === 'male') rankMales.push(memberData);
              else rankFemales.push(memberData);
            })

            rankMales.sort(function (a, b) {
              let x = a.name;
              let y = b.name;

              if (x > y) return 1;
              if (x < y) return -1;
            })

            rankFemales.sort(function (a, b) {
              let x = a.name;
              let y = b.name;

              if (x > y) return 1;
              if (x < y) return -1;
            })

            finalRankMembers = rankMales.concat(rankFemales);

            data.members = finalRankMembers;
          })

          setEvent3RankingList(event3ValidRank);
          // Event 3 순위 산출 //

          // Event 4 순위 산출 //
          let event4ValidRank = [];
          let event4TimeValidRank = [];
          let event4RepsValidRank = [];
          let event4InvalidRank = [];
          let event4TotalRank = [];

          event4.map((data) => {

            let newMembers = data.members.slice();

            newMembers.sort((a, b) => a.gender.localeCompare(b.gender) || b.division.localeCompare(a.division) || a.memberId - b.memberId);

            data.members = newMembers;

            if (isClickedDivision === 2) {
              if (data.members[0].gender === data.members[1].gender
                && data.members[0].gender === 'male') {
                
                if (parseInt(data.firstExercise) > 0) event4TimeValidRank.push(data);
                else if (data.completeRep > 0) event4RepsValidRank.push(data);
                else event4InvalidRank.push(data);
              }
            }
            else {
              if (data.members[0].gender !== data.members[1].gender) {
                if (parseInt(data.firstExercise) > 0) event4TimeValidRank.push(data);
                else if (data.completeRep > 0) event4RepsValidRank.push(data);
                else event4InvalidRank.push(data);
              }
            }
          })

          // 남성 데이터 순위 정렬 //
          // event4ValidRank.sort((a, b) => b.totalReps - a.totalReps || a.totalTieBreak - b.totalTieBreak);
          event4TimeValidRank.sort((a, b) => a.firstExercise - b.firstExercise || a.time - b.time);
          event4RepsValidRank.sort((a, b) => b.completeRep - a.completeRep || a.time - b.time);

          // // 올바르지 않은 데이터는 이름순 정렬 //
          // event4InvalidRank.sort(function (a, b) {
          //   let x = a.name;
          //   let y = b.name;

          //   if (x > y) return 1;
          //   if (x < y) return -1;
          // })
          event4InvalidRank.sort((a, b) => a.firstExercise - b.firstExercise || a.name.localeCompare(b.name));

          event4ValidRank = event4TimeValidRank.concat(event4RepsValidRank);

          for (let i = 0; i < event4ValidRank.length; i++) {
            if (i !== 0
              && parseInt(event4ValidRank[i].firstExercise) !== 0
              && event4ValidRank[i].firstExercise === event4ValidRank[i - 1].firstExercise
              && event4ValidRank[i].time === event4ValidRank[i - 1].time)
              event4ValidRank[i].rank = event4ValidRank[i - 1].rank;
            else if (i !== 0
              && event4ValidRank[i].completeRep !== 0
              && event4ValidRank[i].completeRep === event4ValidRank[i - 1].completeRep
              && event4ValidRank[i].time === event4ValidRank[i - 1].time)
              event4ValidRank[i].rank = event4ValidRank[i - 1].rank;
            else event4ValidRank[i].rank = (i + 1);
          }

          for (let i = 0; i < event4InvalidRank.length; i++) {
            // if (event4ValidRank.length === 0) event4InvalidRank[i].rank = 0;
            // else event4InvalidRank[i].rank = event4ValidRank.length + event4InvalidRank.length - 1;
            event4InvalidRank[i].rank = 0;
          }
          
          event4TotalRank = event4ValidRank.concat(event4InvalidRank);
          
          // 남성 여성 순으로 정렬 //
          event4TotalRank.map((data) => {
            let rankMembers = data.members;
            let finalRankMembers = [];

            let rankMales = [];
            let rankFemales = [];

            rankMembers.map((memberData) => {
              if (memberData.gender === 'male') rankMales.push(memberData);
              else rankFemales.push(memberData);
            })

            rankMales.sort(function (a, b) {
              let x = a.name;
              let y = b.name;

              if (x > y) return 1;
              if (x < y) return -1;
            })

            rankFemales.sort(function (a, b) {
              let x = a.name;
              let y = b.name;

              if (x > y) return 1;
              if (x < y) return -1;
            })

            finalRankMembers = rankMales.concat(rankFemales);

            data.members = finalRankMembers;
          })

          setEvent4RankingList(event4ValidRank);
          // Event 4 순위 산출 //


          // Event 5 순위 산출 //
          let event5ValidRank = [];
          let event5TimeValidRank = [];
          let event5RepsValidRank = [];
          let event5InvalidRank = [];
          let event5TotalRank = [];

          event5.map((data) => {
            if (isClickedDivision === 2) {
              if (data.members[0].gender === data.members[1].gender
                && data.members[0].gender === 'male') {
                
                if (parseInt(data.secondExercise) > 0) event5TimeValidRank.push(data);
                else if (data.completeRound > 0) event5RepsValidRank.push(data);
                else event5InvalidRank.push(data);
              }
            }
            else {
              if (data.members[0].gender !== data.members[1].gender) {
                if (parseInt(data.secondExercise) > 0) event5TimeValidRank.push(data);
                else if (data.completeRound > 0) event5RepsValidRank.push(data);
                else event5InvalidRank.push(data);
              }
            }
          })
          
          // 남성 데이터 순위 정렬 //
          // event4ValidRank.sort((a, b) => b.totalReps - a.totalReps || a.totalTieBreak - b.totalTieBreak);
          event5TimeValidRank.sort((a, b) => a.secondExercise - b.secondExercise || a.tieBreak - b.tieBreak);
          event5RepsValidRank.sort((a, b) => b.completeRound - a.completeRound || a.tieBreak - b.tieBreak);

          // // 올바르지 않은 데이터는 이름순 정렬 //
          // event5InvalidRank.sort(function (a, b) {
          //   let x = a.name;
          //   let y = b.name;

          //   if (x > y) return 1;
          //   if (x < y) return -1;
          // })
          event5InvalidRank.sort((a, b) => a.secondExercise - b.secondExercise || a.name.localeCompare(b.name));

          event5ValidRank = event5TimeValidRank.concat(event5RepsValidRank);

          for (let i = 0; i < event5ValidRank.length; i++) {
            if (i !== 0
              && parseInt(event5ValidRank[i].secondExercise) !== 0
              && event5ValidRank[i].secondExercise === event5ValidRank[i - 1].secondExercise
              && event5ValidRank[i].tieBreak === event5ValidRank[i - 1].tieBreak)
              event5ValidRank[i].rank = event5ValidRank[i - 1].rank;
            else if (i !== 0
              && event5ValidRank[i].completeRound !== 0
              && event5ValidRank[i].completeRound === event5ValidRank[i - 1].completeRound
              && event5ValidRank[i].tieBreak === event5ValidRank[i - 1].tieBreak)
              event5ValidRank[i].rank = event5ValidRank[i - 1].rank;
            else event5ValidRank[i].rank = (i + 1);
          }

          for (let i = 0; i < event5InvalidRank.length; i++) {
            // if (event5ValidRank.length === 0) event5InvalidRank[i].rank = 0;
            // else event5InvalidRank[i].rank = event5ValidRank.length + event5InvalidRank.length - 1;
            event5InvalidRank[i].rank = 0;
          }

          // console.log('5');
          // console.log(event5TimeValidRank);
          // console.log(event5RepsValidRank);
          // console.log(event5InvalidRank);
          // console.log(event5ValidRank.length);
          // console.log(event5InvalidRank.length);
          // console.log(event5ValidRank.length + event5InvalidRank.length - 1);
          event5TotalRank = event5ValidRank.concat(event5InvalidRank);

          // 남성 여성 순으로 정렬 //
          event5TotalRank.map((data) => {
            let rankMembers = data.members;
            let finalRankMembers = [];

            let rankMales = [];
            let rankFemales = [];

            rankMembers.map((memberData) => {
              if (memberData.gender === 'male') rankMales.push(memberData);
              else rankFemales.push(memberData);
            })

            rankMales.sort(function (a, b) {
              let x = a.name;
              let y = b.name;

              if (x > y) return 1;
              if (x < y) return -1;
            })

            rankFemales.sort(function (a, b) {
              let x = a.name;
              let y = b.name;

              if (x > y) return 1;
              if (x < y) return -1;
            })

            finalRankMembers = rankMales.concat(rankFemales);

            data.members = finalRankMembers;
          })

          setEvent5RankingList(event5ValidRank);
          // Event 5 순위 산출 //


          let totalRank = [];
          
          // Event1 데이터 정리 //
          for (let i = 0; i < event1TotalRank.length; i++) {
            totalRank.push({
              gender: event1TotalRank[i].gender,
              name: event1TotalRank[i].name,
              profile: event1TotalRank[i].profile,
              members: event1TotalRank[i].members,
              userId: event1TotalRank[i].userId,
              teamId: event1TotalRank[i].teamId,
              belong: event1TotalRank[i].belong,
              event1: {
                rank: event1TotalRank[i].rank,
                time: event1TotalRank[i].time,
                completeRep: event1TotalRank[i].completeRep,
                tieBreak: event1TotalRank[i].tieBreak
              },
              event2: {
                rank: 0,
                completeRep: 0,
                totalWeight: 0,
                completeRound: 0,
                lastExerciseRep: 0,
              },
              event3: {
                rank: 0,
                completeRep: 0,
                totalWeight: 0,
                completeRound: 0,
                lastExerciseRep: 0,
              },
              event4: {
                rank: 0,
                firstExercise: 0,
                secondExercise: 0,
                completeRep: 0,
                completeRound: 0,
                time: 0,
                tieBreak: 0
              },
              event5: {
                rank: 0,
                firstExercise: 0,
                secondExercise: 0,
                completeRep: 0,
                completeRound: 0,
                time: 0,
                tieBreak: 0
              }
            })
          }
          // Event1 데이터 정리 //

          // Event 2 데이터 정리 //
          for (let i = 0; i < event2TotalRank.length; i++) {
            let isExist = false;

            for (let j = 0; j < totalRank.length; j++) {
              if (totalRank[j].teamId === event2TotalRank[i].teamId) {
                totalRank[j].event2.rank = event2TotalRank[i].rank;
                totalRank[j].event2.completeRep = event2TotalRank[i].completeRep;
                totalRank[j].event2.totalWeight = event2TotalRank[i].totalWeight;
                totalRank[j].event2.completeRound = event2TotalRank[i].completeRound;
                totalRank[j].event2.lastExerciseRep = event2TotalRank[i].lastExerciseRep;
                isExist = true;
                break;
              }
            }

            // 데이터가 삽입되어있지 않은 경우 //
            if (!isExist) {
              totalRank.push({
                gender: event2TotalRank[i].gender,
                name: event2TotalRank[i].name,
                profile: event2TotalRank[i].profile,
                members: event2TotalRank[i].members,
                userId: event2TotalRank[i].userId,
                teamId: event2TotalRank[i].teamId,
                belong: event2TotalRank[i].belong,
                event1: {
                  rank: 0,
                  time: 0,
                  completeRep: 0,
                  tieBreak: 0
                },
                event2: {
                  rank: event2TotalRank[i].rank,
                  completeRep: event2TotalRank[i].completeRep,
                  totalWeight: event2TotalRank[i].totalWeight,
                  completeRound: event2TotalRank[i].completeRound,
                  lastExerciseRep: event2TotalRank[i].lastExerciseRep
                },
                event3: {
                  rank: 0,
                  completeRep: 0,
                  totalWeight: 0,
                  completeRound: 0,
                  lastExerciseRep: 0,
                },
                event4: {
                  rank: 0,
                  firstExercise: 0,
                  secondExercise: 0,
                  completeRep: 0,
                  completeRound: 0,
                  time: 0,
                  tieBreak: 0
                },
                event5: {
                  rank: 0,
                  firstExercise: 0,
                  secondExercise: 0,
                  completeRep: 0,
                  completeRound: 0,
                  time: 0,
                  tieBreak: 0
                }
              })
            }
          }
          // Event 2 데이터 정리 //

          // Event 3 데이터 정리 //
          for (let i = 0; i < event3TotalRank.length; i++) {
            let isExist = false;

            for (let j = 0; j < totalRank.length; j++) {
              if (totalRank[j].teamId === event3TotalRank[i].teamId) {
                totalRank[j].event3.rank = event3TotalRank[i].rank;
                totalRank[j].event3.completeRep = event3TotalRank[i].completeRep;
                totalRank[j].event3.totalWeight = event3TotalRank[i].totalWeight;
                totalRank[j].event3.completeRound = event3TotalRank[i].completeRound;
                totalRank[j].event3.lastExerciseRep = event3TotalRank[i].lastExerciseRep;
                isExist = true;
                break;
              }
            }

            // 데이터가 삽입되어있지 않은 경우 //
            if (!isExist) {
              totalRank.push({
                gender: event3TotalRank[i].gender,
                name: event3TotalRank[i].name,
                profile: event3TotalRank[i].profile,
                members: event3TotalRank[i].members,
                userId: event3TotalRank[i].userId,
                teamId: event3TotalRank[i].teamId,
                belong: event3TotalRank[i].belong,
                event1: {
                  rank: 0,
                  time: 0,
                  completeRep: 0,
                  tieBreak: 0
                },
                event2: {
                  rank: 0,
                  completeRep: 0,
                  totalWeight: 0,
                  completeRound: 0,
                  lastExerciseRep: 0,
                },
                event3: {
                  rank: event3TotalRank[i].rank,
                  completeRep: event3TotalRank[i].completeRep,
                  totalWeight: event3TotalRank[i].totalWeight,
                  completeRound: event3TotalRank[i].completeRound,
                  lastExerciseRep: event3TotalRank[i].lastExerciseRep
                },
                event4: {
                  rank: 0,
                  firstExercise: 0,
                  secondExercise: 0,
                  completeRep: 0,
                  completeRound: 0,
                  time: 0,
                  tieBreak: 0
                },
                event5: {
                  rank: 0,
                  firstExercise: 0,
                  secondExercise: 0,
                  completeRep: 0,
                  completeRound: 0,
                  time: 0,
                  tieBreak: 0
                }
              })
            }
          }
          // Event 3 데이터 정리 //

          // Event 4 데이터 정리 //
          for (let i = 0; i < event4TotalRank.length; i++) {
            let isExist = false;

            for (let j = 0; j < totalRank.length; j++) {
              if (totalRank[j].teamId === event4TotalRank[i].teamId) {
                totalRank[j].event4.rank = event4TotalRank[i].rank;
                totalRank[j].event4.firstExercise = event4TotalRank[i].firstExercise;
                totalRank[j].event4.secondExercise = event4TotalRank[i].secondExercise;
                totalRank[j].event4.completeRep = event4TotalRank[i].completeRep;
                totalRank[j].event4.completeRound = event4TotalRank[i].completeRound;
                totalRank[j].event4.time = event4TotalRank[i].time;
                totalRank[j].event4.tieBreak = event4TotalRank[i].tieBreak;
                isExist = true;
                break;
              }
            }

            // 데이터가 삽입되어있지 않은 경우 //
            if (!isExist) {
              totalRank.push({
                gender: event4TotalRank[i].gender,
                name: event4TotalRank[i].name,
                profile: event4TotalRank[i].profile,
                members: event4TotalRank[i].members,
                userId: event4TotalRank[i].userId,
                teamId: event4TotalRank[i].teamId,
                belong: event4TotalRank[i].belong,
                event1: {
                  rank: 0,
                  time: 0,
                  completeRep: 0,
                  tieBreak: 0
                },
                event2: {
                  rank: 0,
                  completeRep: 0,
                  totalWeight: 0,
                  completeRound: 0,
                  lastExerciseRep: 0,
                },
                event3: {
                  rank: 0,
                  completeRep: 0,
                  totalWeight: 0,
                  completeRound: 0,
                  lastExerciseRep: 0,
                },
                event4: {
                  rank: event4TotalRank[i].rank,
                  firstExercise: event4TotalRank[i].firstExercise,
                  secondExercise: event4TotalRank[i].secondExercise,
                  completeRep: event4TotalRank[i].completeRep,
                  completeRound: event4TotalRank[i].completeRound,
                  time: event4TotalRank[i].time,
                  tieBreak: event4TotalRank[i].tieBreak
                },
                event5: {
                  rank: 0,
                  firstExercise: 0,
                  secondExercise: 0,
                  completeRep: 0,
                  completeRound: 0,
                  time: 0,
                  tieBreak: 0
                }
              })
            }
          }
          // Event 4 데이터 정리 //

          // Event 5 데이터 정리 //
          for (let i = 0; i < event5TotalRank.length; i++) {
            let isExist = false;

            for (let j = 0; j < totalRank.length; j++) {
              if (totalRank[j].teamId === event5TotalRank[i].teamId) {
                totalRank[j].event5.rank = event5TotalRank[i].rank;
                totalRank[j].event5.firstExercise = event5TotalRank[i].firstExercise;
                totalRank[j].event5.secondExercise = event5TotalRank[i].secondExercise;
                totalRank[j].event5.completeRep = event5TotalRank[i].completeRep;
                totalRank[j].event5.completeRound = event5TotalRank[i].completeRound;
                totalRank[j].event5.time = event5TotalRank[i].time;
                totalRank[j].event5.tieBreak = event5TotalRank[i].tieBreak;
                isExist = true;
                break;
              }
            }

            // 데이터가 삽입되어있지 않은 경우 //
            if (!isExist) {
              totalRank.push({
                gender: event5TotalRank[i].gender,
                name: event5TotalRank[i].name,
                profile: event5TotalRank[i].profile,
                members: event5TotalRank[i].members,
                userId: event5TotalRank[i].userId,
                teamId: event5TotalRank[i].teamId,
                belong: event5TotalRank[i].belong,
                event1: {
                  rank: 0,
                  time: 0,
                  completeRep: 0,
                  tieBreak: 0
                },
                event2: {
                  rank: 0,
                  completeRep: 0,
                  totalWeight: 0,
                  completeRound: 0,
                  lastExerciseRep: 0,
                },
                event3: {
                  rank: 0,
                  completeRep: 0,
                  totalWeight: 0,
                  completeRound: 0,
                  lastExerciseRep: 0,
                },
                event4: {
                  rank: 0,
                  firstExercise: 0,
                  secondExercise: 0,
                  completeRep: 0,
                  completeRound: 0,
                  time: 0,
                  tieBreak: 0
                },
                event5: {
                  rank: event5TotalRank[i].rank,
                  firstExercise: event5TotalRank[i].firstExercise,
                  secondExercise: event5TotalRank[i].secondExercise,
                  completeRep: event5TotalRank[i].completeRep,
                  completeRound: event5TotalRank[i].completeRound,
                  time: event5TotalRank[i].time,
                  tieBreak: event5TotalRank[i].tieBreak
                }
              })
            }
          }
          // Event 5 데이터 정리 //

          // 최종 순위 정리 //
          let finalTotalRank = [];

          for (let i = 0; i < totalRank.length; i++) {
            if (totalRank[i].event1.rank === 0) {
              // totalRank[i].event1.rank = event1TotalRank.length + 1;
              totalRank[i].event1.rank = event1TotalRank.length;
            }

            if (totalRank[i].event2.rank === 0) {
              // totalRank[i].event2.rank = event2TotalRank.length + 1;
              totalRank[i].event2.rank = event2TotalRank.length;
            }

            if (totalRank[i].event3.rank === 0) {
              // totalRank[i].event3.rank = event3TotalRank.length + 1;
              totalRank[i].event3.rank = event3TotalRank.length;
            }

            if (totalRank[i].event4.rank === 0) {
              // totalRank[i].event4.rank = event4TotalRank.length + 1;
              totalRank[i].event4.rank = event4TotalRank.length;
            }

            if (totalRank[i].event5.rank === 0) {
              // totalRank[i].event5.rank = event5TotalRank.length + 1;
              totalRank[i].event5.rank = event5TotalRank.length;
            }
          }

          totalRank.map((data) => {
            data.rank = -1;
            data.score = (data.event1.rank + data.event2.rank + data.event3.rank + data.event4.rank + data.event5.rank);
          })

          // 최종 순위 정렬 //
          totalRank.sort((a, b) => a.score - b.score);

          // 순위 산출 //
          for (let i = 0; i < totalRank.length; i++) {
            if (i !== 0 && totalRank[i].score === 0) {
              totalRank[i].rank = totalRank[i - 1].rank;
            }
            else if (i !== 0
              && totalRank[i].score === totalRank[i - 1].score) {
              totalRank[i].rank = totalRank[i - 1].rank;
            }
            else {
              totalRank[i].rank = (i + 1);
            }
          }

          // finalTotalRank = maleTotalRank.concat(femaleTotalRank);

          if (myRankData !== undefined) {
            // console.log(myRankData);
            // Event 1 내순위 찾기 //
            for (let i = 0; i < event1TotalRank.length; i++) {
              if (event1TotalRank[i].teamId === myRankData.teamId) {
                setEvent1MyRank(event1TotalRank[i]);
                break;
              }
            }

            // Event 2 내순위 찾기 //
            for (let i = 0; i < event2TotalRank.length; i++) {
              if (event2TotalRank[i].teamId === myRankData.teamId) {
                setEvent2MyRank(event2TotalRank[i]);
                break;
              }
            }

            // Event 3 내순위 찾기 //
            for (let i = 0; i < event3TotalRank.length; i++) {
              if (event3TotalRank[i].teamId === myRankData.teamId) {
                setEvent3MyRank(event3TotalRank[i]);
                break;
              }
            }

            // Event 4 내순위 찾기 //
            for (let i = 0; i < event4TotalRank.length; i++) {
              if (event4TotalRank[i].teamId === myRankData.teamId) {
                setEvent4MyRank(event4TotalRank[i]);
                break;
              }
            }

            // Event 5 내순위 찾기 //
            for (let i = 0; i < event5TotalRank.length; i++) {
              if (event5TotalRank[i].teamId === myRankData.teamId) {
                setEvent5MyRank(event5TotalRank[i]);
                break;
              }
            }


            // Total 내 순위 찾기 //
            for (let i = 0; i < totalRank.length; i++) {
              if (totalRank[i].teamId === myRankData.teamId) {
                setMyRanking(totalRank[i]);
                break;
              }
              totalRank[i].isClicked = false;
            }
          }

          setResponseRankingList(totalRank);
        }

        setTimeout(() => {
          setIsLoading(false);
        }, 300);
      }
    )();
  }, [nowSection, eventId, isClickedDivision])

  useEffect(() => {
    if (myRanking === undefined) return;

    if (myRanking.members[0].gender === myRanking.members[1].gender
      && myRanking.members[0].gender === 'male') setMyDivision(2);
    else setMyDivision(1);
    
  }, [myRanking])

  // 내 순위 자세히 보기에 따른 컴포넌트 크기 변환 //
  useEffect(() => {
    if (isClickedMyRank) {

      // if (data.members.length < 4) return 452;
      // else if (data.members.length < 7) return 523;
      // else return 593;
      if (nowSection === 'Total') {
        if (myRanking.members.length < 4) {
          // setMyRankHeight(148);
          // setMyRankTotalHeight(319);
          setMyRankHeight(148);
          setMyRankTotalHeight(360);

          // if (moment().isAfter(openDate)) {
          //   setMyRankHeight(198);
          //   setMyRankTotalHeight(380);
          // }
          // else {
          //   // setMyRankHeight(148);
          //   // setMyRankTotalHeight(260);
          //   setMyRankHeight(198);
          //   setMyRankTotalHeight(330);
          // }
          // if (data.members.length < 4) return 330;
          // else if (data.members.length < 7) return 460;
          // else return 593;
        }
        else if (myRanking.members.length < 7) {
          // setMyRankHeight(219);
          // setMyRankTotalHeight(401);
          setMyRankHeight(219);
          setMyRankTotalHeight(439);
          // setMyRankTotalHeight(279);
        }
        else {
          // setMyRankHeight(289);
          // setMyRankTotalHeight(593);
          setMyRankHeight(593);
          // setMyRankTotalHeight(389);
          setMyRankTotalHeight(553);
        }
      }
      else if (nowSection === 'C') {
        if (myRanking.members.length < 4) {
          setMyRankTotalHeight(232);
          setMyRankHeight(230);
        }
        else if (myRanking.members.length < 7) {
          setMyRankTotalHeight(297);
          setMyRankHeight(295);
        }
        else {
          setMyRankTotalHeight(377);
          setMyRankHeight(365);
        }
      }
      else if (nowSection === 'D' || nowSection === 'E') {
        setMyRankTotalHeight(180);
        setMyRankHeight(160);
      }
      else {
        if (myRanking.members.length < 4) {
          // setMyRankTotalHeight(150);
          // setMyRankHeight(148);
          setMyRankTotalHeight(200);
          setMyRankHeight(198);
        }
        else if (myRanking.members.length < 7) {
          setMyRankTotalHeight(221);
          setMyRankHeight(219);
        }
        else {
          setMyRankTotalHeight(291);
          setMyRankHeight(289);
        }
      }
    }
    else {
      setMyRankTotalHeight(64);
      setMyRankHeight(62);
    }
  }, [isClickedMyRank, myRanking, nowSection, openDate])

  // Message Detect Save And Delete //
  useEffect(() => {
    // Android //
    document.addEventListener('message', handleMassage);

    // IOS //
    window.addEventListener('message', handleMassage);

    return () => {
      // Android //
      document.removeEventListener('message', handleMassage);

      // IOS //
      window.removeEventListener('message', handleMassage);
    }
  }, [])

  // 페이지 이름 변경내역 업로드 //
  useEffect(() => {
    try {
      window.ReactNativeWebView.postMessage(JSON.stringify({ pageName: t('순위표'), isAvailableGoback: false }));
    }
    catch (e) {
      console.log("Web Message Post Error : " + e);
    }
  }, []);

  // React Native에서 웹으로 보내는 데이터 handler //
  async function handleMassage(e) {
    let responseData = JSON.parse(e.data);

    if (responseData.goBack) {
      await window.localStorage.removeItem('GenderDivision');
      navigate(-1);
    }
  }

  // 내순위 팀 구성원 자세히보기 클릭시 //
  function onClickMyRecordDetailButton() {
    // if (nowSection !== 'Total') return;

    let data = responseRankingList.slice();

    for (let i = 0; i < data.length; i++) {
      data[i].isClicked = false;
    }

    setResponseRankingList(data);
    setIsClickedMyRank(!isClickedMyRank);
  }

  // 팀 구성원 자세히보기 클릭시 //
  function onClickRecordDetailButton(index) {
    let data = responseRankingList.slice();

    for (let i = 0; i < data.length; i++) {
      if (i !== index) data[i].isClicked = false;
    }

    data[index].isClicked = !data[index].isClicked;

    setIsClickedMyRank(false);
    setResponseRankingList(data);
  }

  // 걸린시간 분/초로 계산 //
  function calcTime(data) {
    if (nowSection === 'A') {
      if (data.time > 0) {
        let time = '';

        let min = 0;
        let sec = 0;
        let decimalPoint = (data.time % 1).toFixed(2);

        min = parseInt(data.time / 60);

        if (decimalPoint !== '0.00') sec = parseInt(data.time % 60) + parseFloat(decimalPoint);
        else sec = parseInt(data.time % 60);

        if (min !== 0 || sec !== 0) {
          if (sec === 0) {
            if (min < 10) time = '0' + min + ':00';
            else time = min + ':00';
          }
          else {
            if (min < 10) {
              if (sec < 10) time = '0' + min + ':0' + sec;
              else time = '0' + min + ':' + sec;
            }
            else {
              if (sec < 10) time = min + ':0' + sec;
              else time = min + ':' + sec;
            }
          }
        }

        let tieBreak = '';

        let tieMin = 0;
        let tieSec = 0;
        let tieDecimalPoint = (data.tieBreak % 1).toFixed(2);

        tieMin = parseInt(data.tieBreak / 60);

        if (tieDecimalPoint !== '0.00') tieSec = parseInt(data.tieBreak % 60) + parseFloat(tieDecimalPoint);
        else tieSec = parseInt(data.tieBreak % 60);

        if (tieMin !== 0 || tieSec !== 0) {
          if (tieSec === 0) {
            if (tieMin < 10) tieBreak = '0' + tieMin + ':00';
            else tieBreak = tieMin + ':00';
          }
          else {
            if (tieMin < 10) {
              if (tieSec < 10) tieBreak = '0' + tieMin + ':0' + tieSec;
              else tieBreak = '0' + tieMin + ':' + tieSec;
            }
            else {
              if (tieSec < 10) tieBreak = tieMin + ':0' + tieSec;
              else tieBreak = tieMin + ':' + tieSec;
            }
          }
        }

        if (tieBreak === '') return time;
        else return time + ' - TieBreak ' + tieBreak;
      }
      else if (data.completeRep > 0) {
        let tieBreak = '';

        let tieMin = 0;
        let tieSec = 0;
        let tieDecimalPoint = (data.tieBreak % 1).toFixed(2);

        tieMin = parseInt(data.tieBreak / 60);

        if (tieDecimalPoint !== '0.00') tieSec = parseInt(data.tieBreak % 60) + parseFloat(tieDecimalPoint);
        else tieSec = parseInt(data.tieBreak % 60);

        if (tieMin !== 0 || tieSec !== 0) {
          if (tieSec === 0) {
            if (tieMin < 10) tieBreak = '0' + tieMin + ':00';
            else tieBreak = tieMin + ':00';
          }
          else {
            if (tieMin < 10) {
              if (tieSec < 10) tieBreak = '0' + tieMin + ':0' + tieSec;
              else tieBreak = '0' + tieMin + ':' + tieSec;
            }
            else {
              if (tieSec < 10) tieBreak = tieMin + ':0' + tieSec;
              else tieBreak = tieMin + ':' + tieSec;
            }
          }
        }

        if (tieBreak === '') return data.completeRep + ' Reps';
        else return data.completeRep + ' Reps - TieBreak ' + tieBreak;
      }
      else if (data.time < 0) return '실격';
      else return '미제출';
    }
    else if (nowSection === 'B') {
      if (data.completeRep > 0) {
        if (data.totalWeight === 0) return data.completeRep + ' Point';
        else return data.completeRep + ' Point - TB ' + data.totalWeight + ' lbs';
      }
      else if (data.completeRep < 0) return '실격';
      else return '미제출';
    }
    else if (nowSection === 'C') {
      if (data.totalWeight > 0) {
        if (data.completeRep !== 0) return data.totalWeight + ' lbs - TB ' + data.completeRep + ' Point';
        else return data.totalWeight + ' lbs';
      }
      else if (data.totalWeight < 0) return '실격';
      else return '미제출';
    }
    else if (nowSection === 'D') {
      if (parseInt(data.firstExercise) > 0) {
        let time = '';

        let min = 0;
        let sec = 0;
        let decimalPoint = (parseInt(data.firstExercise) % 1).toFixed(2);

        min = parseInt(data.firstExercise / 60);

        if (decimalPoint !== '0.00') sec = parseInt(data.firstExercise % 60) + parseFloat(decimalPoint);
        else sec = parseInt(data.firstExercise % 60);

        if (min !== 0 || sec !== 0) {
          if (sec === 0) {
            if (min < 10) time = '0' + min + ':00';
            else time = min + ':00';
          }
          else {
            if (min < 10) {
              if (sec < 10) time = '0' + min + ':0' + sec;
              else time = '0' + min + ':' + sec;
            }
            else {
              if (sec < 10) time = min + ':0' + sec;
              else time = min + ':' + sec;
            }
          }
        }

        let tieBreak = '';

        let tieBreakMin = 0;
        let tieBreakSec = 0;
        let tieBreakDecimalPoint = (data.time % 1).toFixed(2);

        tieBreakMin = parseInt(data.time / 60);

        if (tieBreakDecimalPoint !== '0.00') tieBreakSec = parseInt(data.time % 60) + parseFloat(tieBreakDecimalPoint);
        else tieBreakSec = parseInt(data.time % 60);

        if (tieBreakMin !== 0 || tieBreakSec !== 0) {
          if (tieBreakSec === 0) {
            if (tieBreakMin < 10) tieBreak = '0' + tieBreakMin + ':00';
            else tieBreak = tieBreakMin + ':00';
          }
          else {
            if (tieBreakMin < 10) {
              if (tieBreakSec < 10) tieBreak = '0' + tieBreakMin + ':0' + tieBreakSec;
              else tieBreak = '0' + tieBreakMin + ':' + tieBreakSec;
            }
            else {
              if (tieBreakSec < 10) tieBreak = tieBreakMin + ':0' + tieBreakSec;
              else tieBreak = tieBreakMin + ':' + tieBreakSec;
            }
          }
        }
        
        if (tieBreak === '') return time;
        else return time + ' - TieBreak ' + tieBreak;
      } 
      else if (data.completeRep > 0) {
        let tieBreak = '';

        let tieBreakMin = 0;
        let tieBreakSec = 0;
        let tieBreakDecimalPoint = (data.time % 1).toFixed(2);

        tieBreakMin = parseInt(data.time / 60);

        if (tieBreakDecimalPoint !== '0.00') tieBreakSec = parseInt(data.time % 60) + parseFloat(tieBreakDecimalPoint);
        else tieBreakSec = parseInt(data.time % 60);

        if (tieBreakMin !== 0 || tieBreakSec !== 0) {
          if (tieBreakSec === 0) {
            if (tieBreakMin < 10) tieBreak = '0' + tieBreakMin + ':00';
            else tieBreak = tieBreakMin + ':00';
          }
          else {
            if (tieBreakMin < 10) {
              if (tieBreakSec < 10) tieBreak = '0' + tieBreakMin + ':0' + tieBreakSec;
              else tieBreak = '0' + tieBreakMin + ':' + tieBreakSec;
            }
            else {
              if (tieBreakSec < 10) tieBreak = tieBreakMin + ':0' + tieBreakSec;
              else tieBreak = tieBreakMin + ':' + tieBreakSec;
            }
          }
        }
        
        if (tieBreak === '') return data.completeRep + ' REPS';
        else return data.completeRep + ' REPS - TieBreak ' + tieBreak;
      }
      else if (parseInt(data.firstExercise) < 0) return '실격';
      else return '미제출';
    }
    else if (nowSection === 'E') {
      if (parseInt(data.secondExercise) > 0) {
        let time = '';

        let min = 0;
        let sec = 0;
        let decimalPoint = (parseInt(data.secondExercise) % 1).toFixed(2);

        min = parseInt(data.secondExercise / 60);

        if (decimalPoint !== '0.00') sec = parseInt(data.secondExercise % 60) + parseFloat(decimalPoint);
        else sec = parseInt(data.secondExercise % 60);

        if (min !== 0 || sec !== 0) {
          if (sec === 0) {
            if (min < 10) time = '0' + min + ':00';
            else time = min + ':00';
          }
          else {
            if (min < 10) {
              if (sec < 10) time = '0' + min + ':0' + sec;
              else time = '0' + min + ':' + sec;
            }
            else {
              if (sec < 10) time = min + ':0' + sec;
              else time = min + ':' + sec;
            }
          }
        }

        let tieBreak = '';

        let tieBreakMin = 0;
        let tieBreakSec = 0;
        let tieBreakDecimalPoint = (data.tieBreak % 1).toFixed(2);

        tieBreakMin = parseInt(data.tieBreak / 60);

        if (tieBreakDecimalPoint !== '0.00') tieBreakSec = parseInt(data.tieBreak % 60) + parseFloat(tieBreakDecimalPoint);
        else tieBreakSec = parseInt(data.tieBreak % 60);

        if (tieBreakMin !== 0 || tieBreakSec !== 0) {
          if (tieBreakSec === 0) {
            if (tieBreakMin < 10) tieBreak = '0' + tieBreakMin + ':00';
            else tieBreak = tieBreakMin + ':00';
          }
          else {
            if (tieBreakMin < 10) {
              if (tieBreakSec < 10) tieBreak = '0' + tieBreakMin + ':0' + tieBreakSec;
              else tieBreak = '0' + tieBreakMin + ':' + tieBreakSec;
            }
            else {
              if (tieBreakSec < 10) tieBreak = tieBreakMin + ':0' + tieBreakSec;
              else tieBreak = tieBreakMin + ':' + tieBreakSec;
            }
          }
        }
        
        if (tieBreak === '') return time;
        else return time + ' - TieBreak ' + tieBreak;
      } 
      else if (data.completeRound > 0) {
        let tieBreak = '';

        let tieBreakMin = 0;
        let tieBreakSec = 0;
        let tieBreakDecimalPoint = (data.tieBreak % 1).toFixed(2);

        tieBreakMin = parseInt(data.tieBreak / 60);

        if (tieBreakDecimalPoint !== '0.00') tieBreakSec = parseInt(data.tieBreak % 60) + parseFloat(tieBreakDecimalPoint);
        else tieBreakSec = parseInt(data.tieBreak % 60);

        if (tieBreakMin !== 0 || tieBreakSec !== 0) {
          if (tieBreakSec === 0) {
            if (tieBreakMin < 10) tieBreak = '0' + tieBreakMin + ':00';
            else tieBreak = tieBreakMin + ':00';
          }
          else {
            if (tieBreakMin < 10) {
              if (tieBreakSec < 10) tieBreak = '0' + tieBreakMin + ':0' + tieBreakSec;
              else tieBreak = '0' + tieBreakMin + ':' + tieBreakSec;
            }
            else {
              if (tieBreakSec < 10) tieBreak = tieBreakMin + ':0' + tieBreakSec;
              else tieBreak = tieBreakMin + ':' + tieBreakSec;
            }
          }
        }
        
        if (tieBreak === '') return data.completeRound + ' REPS';
        else return data.completeRound + ' REPS - TieBreak ' + tieBreak;
      }
      else if (parseInt(data.secondExercise) < 0) return '실격';
      else return '미제출';
    }
    else if (nowSection === 'Total') return data.score + ' Point';
  }


  // 클릭시 최종컴포넌트 늘어나는 크기 정하기 //
  function calcTotalComponentHeight(data) {
    // 자세히 보기가 눌려있는 경우 //
    if (data.isClicked) {
      if (nowSection === 'Total') {
        // if (data.members.length < 4) return 330;
        // else if (data.members.length < 7) return 360;
        // else return 553;
        if (data.members.length < 4) return 380;
        else if (data.members.length < 7) return 410;
        else return 603;
        // if (data.members.length < 4) return 330;
        // else if (data.members.length < 7) return 460;
        // else return 593;
        // if (moment().isAfter(openDate)) {
        //   if (data.members.length < 4) return 400;
        //   else if (data.members.length < 7) return 460;
        //   else return 593;
        // }
        // else {
        //   // if (data.members.length < 4) return 260;
        //   // else if (data.members.length < 7) return 460;
        //   // else return 593;
        //   if (data.members.length < 4) return 330;
        //   else if (data.members.length < 7) return 460;
        //   else return 593;
        // }
      }
      else if (nowSection === 'B' || nowSection === 'C') {
        if (data.members.length < 4) return 220;
        else if (data.members.length < 7) return 321;
        else return 391;
      }
      else if (nowSection === 'D' || nowSection === 'E') return 180;
      else {
        if (data.members.length < 4) return 150;
        else if (data.members.length < 7) return 221;
        else return 291;

        // if (data.members.length < 4) return 200;
        // else if (data.members.length < 7) return 231;
        // else return 301;
      }
    }
    else return 64;
  }

  // 클릭시 팀원 보기 컴포넌트 늘어나는 크기 정하기 //
  function calcComponentHeight(data) {
    // 자세히 보기가 눌려있는 경우 //
    if (data.isClicked) {
      // if (data.members.length < 4) return 148;
      // else if (data.members.length < 7) return 219;
      // else return 289;

      // setMyRankTotalHeight(297);
      // setMyRankHeight(295);
      if (nowSection === 'B' || nowSection === 'C') {
        if (data.members.length < 4) return 200;
        else if (data.members.length < 7) return 299;
        else return 389;
      }
      else if (nowSection === 'D' || nowSection === 'E') return 160;
      else {
        if (data.members.length < 4) return 148;
        else if (data.members.length < 7) return 219;
        else return 289;
      }
    }
    else return 62;
  }

  // 최종 순위표 내의 순위 산출시 배경색 결정 //
  function selectTotalEventRankingBoxBackgroundColor(data) {
    if (nowSection === 'Total' && data === undefined) return '#E0E0E0';
    else if (data.rank === 1) return '#FEB501';
    else if (data.rank === 2) return '#94B1C1';
    else if (data.rank === 3) return '#F38D30';
    else return '#E0E0E0';
  }

  // Total 컬럼에서 A Section 데이터 보기 //
  function calcTotalEvent1View(data) {
    if (data.time > 0) {
      let time = '';

      let min = 0;
      let sec = 0;
      let decimalPoint = (data.time % 1).toFixed(2);

      min = parseInt(data.time / 60);

      if (decimalPoint !== '0.00') sec = parseInt(data.time % 60) + parseFloat(decimalPoint);
      else sec = parseInt(data.time % 60);

      if (min !== 0 || sec !== 0) {
        if (sec === 0) {
          if (min < 10) time = '0' + min + ':00';
          else time = min + ':00';
        }
        else {
          if (min < 10) {
            if (sec < 10) time = '0' + min + ':0' + sec;
            else time = '0' + min + ':' + sec;
          }
          else {
            if (sec < 10) time = min + ':0' + sec;
            else time = min + ':' + sec;
          }
        }
      }

      let tieBreak = '';

      let tieMin = 0;
      let tieSec = 0;
      let tieDecimalPoint = (data.tieBreak % 1).toFixed(2);

      tieMin = parseInt(data.tieBreak / 60);

      if (tieDecimalPoint !== '0.00') tieSec = parseInt(data.tieBreak % 60) + parseFloat(tieDecimalPoint);
      else tieSec = parseInt(data.tieBreak % 60);

      if (tieMin !== 0 || tieSec !== 0) {
        if (tieSec === 0) {
          if (tieMin < 10) tieBreak = '0' + tieMin + ':00';
          else tieBreak = tieMin + ':00';
        }
        else {
          if (tieMin < 10) {
            if (tieSec < 10) tieBreak = '0' + tieMin + ':0' + tieSec;
            else tieBreak = '0' + tieMin + ':' + tieSec;
          }
          else {
            if (tieSec < 10) tieBreak = tieMin + ':0' + tieSec;
            else tieBreak = tieMin + ':' + tieSec;
          }
        }
      }

      if (tieBreak === '') return time;
      else return time + ' - TieBreak ' + tieBreak;
    }
    else if (data.completeRep > 0) {
      let tieBreak = '';

      let tieMin = 0;
      let tieSec = 0;
      let tieDecimalPoint = (data.tieBreak % 1).toFixed(2);

      tieMin = parseInt(data.tieBreak / 60);

      if (tieDecimalPoint !== '0.00') tieSec = parseInt(data.tieBreak % 60) + parseFloat(tieDecimalPoint);
      else tieSec = parseInt(data.tieBreak % 60);

      if (tieMin !== 0 || tieSec !== 0) {
        if (tieSec === 0) {
          if (tieMin < 10) tieBreak = '0' + tieMin + ':00';
          else tieBreak = tieMin + ':00';
        }
        else {
          if (tieMin < 10) {
            if (tieSec < 10) tieBreak = '0' + tieMin + ':0' + tieSec;
            else tieBreak = '0' + tieMin + ':' + tieSec;
          }
          else {
            if (tieSec < 10) tieBreak = tieMin + ':0' + tieSec;
            else tieBreak = tieMin + ':' + tieSec;
          }
        }
      }

      if (tieBreak === '') return data.completeRep + ' Reps';
      else return data.completeRep + ' Reps - TieBreak ' + tieBreak;
    }
    else if (data.time < 0) return '실격';
    else return '미제출';
  }

  // Total 컬럼에서 B Section 데이터 보기 //
  function calcTotalEvent2View(data) {
    if (data.completeRep > 0) {
      if (data.totalWeight === 0) return data.completeRep + ' Point';
      else return data.completeRep + ' Point - TB ' + data.totalWeight + ' lbs';
    }
    else if (data.completeRep < 0) return '실격';
    else return '미제출';
  }

  // Total 컬럼에서 C Section 데이터 보기 //
  function calcTotalEvent3View(data) {
    if (data.totalWeight > 0) {
      if (data.completeRep !== 0) return data.totalWeight + ' lbs - TB ' + data.completeRep + ' Point';
      else return data.totalWeight + ' lbs';
    }
    else if (data.totalWeight < 0) return '실격';
    else return '미제출';
  }

  // Total 컬럼에서 D Section 데이터 보기 //
  function calcTotalEvent4View(data) {
    if (parseInt(data.firstExercise) > 0) {

      let time = '';

      let min = 0;
      let sec = 0;
      let decimalPoint = (data.firstExercise % 1).toFixed(2);

      min = parseInt(data.firstExercise / 60);

      if (decimalPoint !== '0.00') sec = parseInt(data.firstExercise % 60) + parseFloat(decimalPoint);
      else sec = parseInt(data.firstExercise % 60);

      if (min !== 0 || sec !== 0) {
        if (sec === 0) {
          if (min < 10) time = '0' + min + ':00';
          else time = min + ':00';
        }
        else {
          if (min < 10) {
            if (sec < 10) time = '0' + min + ':0' + sec;
            else time = '0' + min + ':' + sec;
          }
          else {
            if (sec < 10) time = min + ':0' + sec;
            else time = min + ':' + sec;
          }
        }
      }

      let tieBreak = '';

      let tieMin = 0;
      let tieSec = 0;
      let tieDecimalPoint = (data.time % 1).toFixed(2);

      tieMin = parseInt(data.time / 60);

      if (tieDecimalPoint !== '0.00') tieSec = parseInt(data.time % 60) + parseFloat(tieDecimalPoint);
      else tieSec = parseInt(data.time % 60);

      if (tieMin !== 0 || tieSec !== 0) {
        if (tieSec === 0) {
          if (tieMin < 10) tieBreak = '0' + tieMin + ':00';
          else tieBreak = tieMin + ':00';
        }
        else {
          if (tieMin < 10) {
            if (tieSec < 10) tieBreak = '0' + tieMin + ':0' + tieSec;
            else tieBreak = '0' + tieMin + ':' + tieSec;
          }
          else {
            if (tieSec < 10) tieBreak = tieMin + ':0' + tieSec;
            else tieBreak = tieMin + ':' + tieSec;
          }
        }
      }

      if (tieBreak === '') return time;
      else return time + ' - TieBreak ' + tieBreak;
    }
    else if (data.completeRep > 0) {
      let tieBreak = '';

      let tieMin = 0;
      let tieSec = 0;
      let tieDecimalPoint = (data.time % 1).toFixed(2);

      tieMin = parseInt(data.time / 60);

      if (tieDecimalPoint !== '0.00') tieSec = parseInt(data.time % 60) + parseFloat(tieDecimalPoint);
      else tieSec = parseInt(data.time % 60);

      if (tieMin !== 0 || tieSec !== 0) {
        if (tieSec === 0) {
          if (tieMin < 10) tieBreak = '0' + tieMin + ':00';
          else tieBreak = tieMin + ':00';
        }
        else {
          if (tieMin < 10) {
            if (tieSec < 10) tieBreak = '0' + tieMin + ':0' + tieSec;
            else tieBreak = '0' + tieMin + ':' + tieSec;
          }
          else {
            if (tieSec < 10) tieBreak = tieMin + ':0' + tieSec;
            else tieBreak = tieMin + ':' + tieSec;
          }
        }
      }

      if (tieBreak === '') return data.completeRep + ' Reps';
      else return data.completeRep + ' Reps - TieBreak ' + tieBreak;
    }
    else if (parseInt(data.firstExercise) < 0) return '실격';
    else return '미제출';
  }

  // Total 컬럼에서 E Section 데이터 보기 //
  function calcTotalEvent5View(data) {
    if (parseInt(data.secondExercise) > 0) {

      let time = '';

      let min = 0;
      let sec = 0;
      let decimalPoint = (data.secondExercise % 1).toFixed(2);

      min = parseInt(data.secondExercise / 60);

      if (decimalPoint !== '0.00') sec = parseInt(data.secondExercise % 60) + parseFloat(decimalPoint);
      else sec = parseInt(data.secondExercise % 60);

      if (min !== 0 || sec !== 0) {
        if (sec === 0) {
          if (min < 10) time = '0' + min + ':00';
          else time = min + ':00';
        }
        else {
          if (min < 10) {
            if (sec < 10) time = '0' + min + ':0' + sec;
            else time = '0' + min + ':' + sec;
          }
          else {
            if (sec < 10) time = min + ':0' + sec;
            else time = min + ':' + sec;
          }
        }
      }

      let tieBreak = '';

      let tieMin = 0;
      let tieSec = 0;
      let tieDecimalPoint = (data.tieBreak % 1).toFixed(2);

      tieMin = parseInt(data.tieBreak / 60);

      if (tieDecimalPoint !== '0.00') tieSec = parseInt(data.tieBreak % 60) + parseFloat(tieDecimalPoint);
      else tieSec = parseInt(data.tieBreak % 60);

      if (tieMin !== 0 || tieSec !== 0) {
        if (tieSec === 0) {
          if (tieMin < 10) tieBreak = '0' + tieMin + ':00';
          else tieBreak = tieMin + ':00';
        }
        else {
          if (tieMin < 10) {
            if (tieSec < 10) tieBreak = '0' + tieMin + ':0' + tieSec;
            else tieBreak = '0' + tieMin + ':' + tieSec;
          }
          else {
            if (tieSec < 10) tieBreak = tieMin + ':0' + tieSec;
            else tieBreak = tieMin + ':' + tieSec;
          }
        }
      }

      if (tieBreak === '') return time;
      else return time + ' - TieBreak ' + tieBreak;
    }
    else if (data.completeRound > 0) {
      let tieBreak = '';

      let tieMin = 0;
      let tieSec = 0;
      let tieDecimalPoint = (data.tieBreak % 1).toFixed(2);

      tieMin = parseInt(data.tieBreak / 60);

      if (tieDecimalPoint !== '0.00') tieSec = parseInt(data.tieBreak % 60) + parseFloat(tieDecimalPoint);
      else tieSec = parseInt(data.tieBreak % 60);

      if (tieMin !== 0 || tieSec !== 0) {
        if (tieSec === 0) {
          if (tieMin < 10) tieBreak = '0' + tieMin + ':00';
          else tieBreak = tieMin + ':00';
        }
        else {
          if (tieMin < 10) {
            if (tieSec < 10) tieBreak = '0' + tieMin + ':0' + tieSec;
            else tieBreak = '0' + tieMin + ':' + tieSec;
          }
          else {
            if (tieSec < 10) tieBreak = tieMin + ':0' + tieSec;
            else tieBreak = tieMin + ':' + tieSec;
          }
        }
      }

      if (tieBreak === '') return data.completeRound + ' Reps';
      else return data.completeRound + ' Reps - TieBreak ' + tieBreak;
    }
    else if (parseInt(data.secondExercise) < 0) return '실격'; 
    else return '미제출';
  }

  // Event 4 기록 보기 //
  function recordView(record, data, index) {

    if (nowSection === 'D') {
      if (index === 0) {
        let finalText = '';

        if (parseInt(record.firstExercise) > 0) {
          let time = '';

          let min = 0;
          let sec = 0;
          let decimalPoint = (parseInt(record.firstExercise) % 1).toFixed(2);
    
          min = parseInt(record.firstExercise / 60);
    
          if (decimalPoint !== '0.00') sec = parseInt(record.firstExercise % 60) + parseFloat(decimalPoint);
          else sec = parseInt(record.firstExercise % 60);
    
          if (min !== 0 || sec !== 0) {
            if (sec === 0) {
              if (min < 10) time = '0' + min + ':00';
              else time = min + ':00';
            }
            else {
              if (min < 10) {
                if (sec < 10) time = '0' + min + ':0' + sec;
                else time = '0' + min + ':' + sec;
              }
              else {
                if (sec < 10) time = min + ':0' + sec;
                else time = min + ':' + sec;
              }
            }
          }

          let tieBreak = '';

          let tieBreakMin = 0;
          let tieBreakSec = 0;
          let tieBreakDecimalPoint = (record.time % 1).toFixed(2);
    
          tieBreakMin = parseInt(record.time / 60);
    
          if (decimalPoint !== '0.00') tieBreakSec = parseInt(record.time % 60) + parseFloat(tieBreakDecimalPoint);
          else tieBreakSec = parseInt(record.time % 60);
    
          if (tieBreakMin !== 0 || tieBreakSec !== 0) {
            if (tieBreakSec === 0) {
              if (tieBreakMin < 10) tieBreak = '0' + tieBreakMin + ':00';
              else tieBreak = tieBreakMin + ':00';
            }
            else {
              if (tieBreakMin < 10) {
                if (tieBreakSec < 10) tieBreak = '0' + tieBreakMin + ':0' + tieBreakSec;
                else tieBreak = '0' + tieBreakMin + ':' + tieBreakSec;
              }
              else {
                if (tieBreakSec < 10) tieBreak = tieBreakMin + ':0' + tieBreakSec;
                else tieBreak = tieBreakMin + ':' + tieBreakSec;
              }
            }
          }
          
          if (tieBreak !== '') finalText = time + ' - TieBreak ' + tieBreak;
          else finalText = time;
        }
        else if (record.completeRep > 0) {
          let tieBreak = '';

          let tieBreakMin = 0;
          let tieBreakSec = 0;
          let tieBreakDecimalPoint = (record.time % 1).toFixed(2);
    
          tieBreakMin = parseInt(record.time / 60);
    
          if (tieBreakDecimalPoint !== '0.00') tieBreakSec = parseInt(record.time % 60) + parseFloat(tieBreakDecimalPoint);
          else tieBreakSec = parseInt(record.time % 60);
    
          if (tieBreakMin !== 0 || tieBreakSec !== 0) {
            if (tieBreakSec === 0) {
              if (tieBreakMin < 10) tieBreak = '0' + tieBreakMin + ':00';
              else tieBreak = tieBreakMin + ':00';
            }
            else {
              if (tieBreakMin < 10) {
                if (tieBreakSec < 10) tieBreak = '0' + tieBreakMin + ':0' + tieBreakSec;
                else tieBreak = '0' + tieBreakMin + ':' + tieBreakSec;
              }
              else {
                if (tieBreakSec < 10) tieBreak = tieBreakMin + ':0' + tieBreakSec;
                else tieBreak = tieBreakMin + ':' + tieBreakSec;
              }
            }
          }
          
          if (tieBreak !== '') finalText = record.completeRep + ' REPS - TieBreak ' + tieBreak;
          else finalText = record.completeRep + ' REPS';
        }
        else if (parseInt(record.firstExercise) < 0) finalText = '실격';
        else finalText = '미제출';

        return (
          <ColorBoxInTotalTextWrapper style={{ flexDirection: 'row' }}>
            <ColorBoxInTextWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>{data.name}</Text>
            </ColorBoxInTextWrapper>
            {/* <Text fontFamily='NotoSansKR-Medium' fontSize={12} color='#000000'>{record.completeRep + ' lbs - Tiebreak ' + time}</Text> */}
            <Text fontFamily='NotoSansKR-Medium' fontSize={12} color='#000000'>{finalText}</Text>
          </ColorBoxInTotalTextWrapper>
        )
      }
    }
    else if (nowSection === 'E') {
      if (index === 1) {

        let finalText = '';

        if (parseInt(record.secondExercise) > 0) {
          let time = '';

          let min = 0;
          let sec = 0;
          let decimalPoint = (parseInt(record.secondExercise) % 1).toFixed(2);
    
          min = parseInt(record.secondExercise / 60);
    
          if (decimalPoint !== '0.00') sec = parseInt(record.secondExercise % 60) + parseFloat(decimalPoint);
          else sec = parseInt(record.secondExercise % 60);
    
          if (min !== 0 || sec !== 0) {
            if (sec === 0) {
              if (min < 10) time = '0' + min + ':00';
              else time = min + ':00';
            }
            else {
              if (min < 10) {
                if (sec < 10) time = '0' + min + ':0' + sec;
                else time = '0' + min + ':' + sec;
              }
              else {
                if (sec < 10) time = min + ':0' + sec;
                else time = min + ':' + sec;
              }
            }
          }

          let tieBreak = '';

          let tieBreakMin = 0;
          let tieBreakSec = 0;
          let tieBreakDecimalPoint = (record.tieBreak % 1).toFixed(2);
    
          tieBreakMin = parseInt(record.tieBreak / 60);
    
          if (tieBreakDecimalPoint !== '0.00') tieBreakSec = parseInt(record.tieBreak % 60) + parseFloat(tieBreakDecimalPoint);
          else tieBreakSec = parseInt(record.tieBreak % 60);
    
          if (tieBreakMin !== 0 || tieBreakSec !== 0) {
            if (tieBreakSec === 0) {
              if (tieBreakMin < 10) tieBreak = '0' + tieBreakMin + ':00';
              else tieBreak = tieBreakMin + ':00';
            }
            else {
              if (tieBreakMin < 10) {
                if (tieBreakSec < 10) tieBreak = '0' + tieBreakMin + ':0' + tieBreakSec;
                else tieBreak = '0' + tieBreakMin + ':' + tieBreakSec;
              }
              else {
                if (tieBreakSec < 10) tieBreak = tieBreakMin + ':0' + tieBreakSec;
                else tieBreak = tieBreakMin + ':' + tieBreakSec;
              }
            }
          }
          
          if (tieBreak !== '') finalText = time + ' - TieBreak ' + tieBreak;
          else finalText = time;
        }
        else if (record.completeRound > 0) {
          let tieBreak = '';

          let tieBreakMin = 0;
          let tieBreakSec = 0;
          let tieBreakDecimalPoint = (record.tieBreak % 1).toFixed(2);
    
          tieBreakMin = parseInt(record.tieBreak / 60);
    
          if (tieBreakDecimalPoint !== '0.00') tieBreakSec = parseInt(record.tieBreak % 60) + parseFloat(tieBreakDecimalPoint);
          else tieBreakSec = parseInt(record.tieBreak % 60);
    
          if (tieBreakMin !== 0 || tieBreakSec !== 0) {
            if (tieBreakSec === 0) {
              if (tieBreakMin < 10) tieBreak = '0' + tieBreakMin + ':00';
              else tieBreak = tieBreakMin + ':00';
            }
            else {
              if (tieBreakMin < 10) {
                if (tieBreakSec < 10) tieBreak = '0' + tieBreakMin + ':0' + tieBreakSec;
                else tieBreak = '0' + tieBreakMin + ':' + tieBreakSec;
              }
              else {
                if (tieBreakSec < 10) tieBreak = tieBreakMin + ':0' + tieBreakSec;
                else tieBreak = tieBreakMin + ':' + tieBreakSec;
              }
            }
          }
          
          if (tieBreak !== '') finalText = record.completeRound + ' REPS - TieBreak ' + tieBreak;
          else finalText = record.completeRound + ' REPS';
        }
        else if (parseInt(record.secondExercise) < 0) finalText = '실격';
        else finalText = '미제출';

        return (
          <ColorBoxInTotalTextWrapper style={{ flexDirection: 'row' }}>
            <ColorBoxInTextWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>{data.name}</Text>
            </ColorBoxInTextWrapper>
            {/* <Text fontFamily='NotoSansKR-Medium' fontSize={12} color='#000000'>{record.completeRound + ' lbs - Tiebreak ' + time}</Text> */}
            <Text fontFamily='NotoSansKR-Medium' fontSize={12} color='#000000'>{finalText}</Text>
          </ColorBoxInTotalTextWrapper>
        )
      }
    }
    else {
      let exercise = '';
      let weight = '';

      if (index === 0) {

        if (record.firstExercise < 0) {
          exercise = '';
          weight = '실격';
        }
        else {
          exercise = record.completeRound === 0 ? 'C&J' : 'Snatch';
          weight = record.firstExercise + ' lbs'
        }

        return (
          <ColorBoxInTotalTextWrapper style={{ flexDirection: 'row' }}>
            <ColorBoxInTextWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>{data.name}</Text>
            </ColorBoxInTextWrapper>
            {/* <Text fontFamily='NotoSansKR-Medium' fontSize={12} color='#000000'>{record.completeRound === 0 ? 'C&J' : 'Snatch'}</Text>
            <Text fontFamily='NotoSansKR-Medium' fontSize={12} color='#000000'>{record.firstExercise + ' lbs'}</Text> */}

            <Text fontFamily='NotoSansKR-Medium' fontSize={12} color='#000000'>{exercise}</Text>
            <Text fontFamily='NotoSansKR-Medium' fontSize={12} color='#000000'>{weight}</Text>
          </ColorBoxInTotalTextWrapper>
        )
      }
      else if (index === 1) {
        if (record.secondExercise < 0) {
          exercise = '';
          weight = '실격';
        }
        else {
          exercise = record.lastExerciseRep === 0 ? 'C&J' : 'Snatch';
          weight = record.secondExercise + ' lbs'
        }

        return (
          <ColorBoxInTotalTextWrapper style={{ flexDirection: 'row' }}>
            <ColorBoxInTextWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>{data.name}</Text>
            </ColorBoxInTextWrapper>
            {/* <Text fontFamily='NotoSansKR-Medium' fontSize={12} color='#000000'>{record.lastExerciseRep === 0 ? 'C&J' : 'Snatch'}</Text>
            <Text fontFamily='NotoSansKR-Medium' fontSize={12} color='#000000'>{record.secondExercise + ' lbs'}</Text> */}

            <Text fontFamily='NotoSansKR-Medium' fontSize={12} color='#000000'>{exercise}</Text>
            <Text fontFamily='NotoSansKR-Medium' fontSize={12} color='#000000'>{weight}</Text>
          </ColorBoxInTotalTextWrapper>
        )
      }
    }
  }

  // 현재 섹션 수정시 //
  function onClickNowSection(data) {
    setIsLoading(true);
    setNowSection(data);
  }

  async function onDownloadRanking() {
    console.log("!!!")
    const ws = await XLSX.utils.json_to_sheet(responseRankingList);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Member")
    XLSX.writeFile(wb, 'TeamOf4_' + nowSection + '_Ranking.xlsx');
    console.log("???")

    // const fetchModule = new FetchModule();
    // (
    //   async () => {
    //     const responseData = await fetchModule.getDataOfZoneVer2('member/attendance/status', 'GET');
    //     console.log(responseData.data);
    //     if (responseData.status === 200) {
    //       let data = responseData.data.slice();
    //       let finalData = [];

    //       data.map((final) => {
    //         let tmp = {
    //           name: final.name,
    //           gender: final.gender,
    //           phone: final.phone,
    //           membershipName: final.memberShipName,
    //           reserveCount: final.reserveCount,
    //           lastAttendanceDate: final.lastAttendanceDate
    //         }

    //         finalData.push(tmp);
    //       })

    //       finalData.sort((a, b) => b.reserveCount - a.reserveCount || a.name - b.name);
    //       const ws = await XLSX.utils.json_to_sheet(finalData);
    //       const wb = XLSX.utils.book_new();
    //       XLSX.utils.book_append_sheet(wb, ws, "Member")
    //       XLSX.writeFile(wb, 'Attandance.xlsx');
    //     }
    //   }
    // )();
  }

  return (
    <TopLevelWrapper >
      <TopLineWrapper>
        <TitleWrapper /*onClick={onDownloadRanking}*/>
          <Text fontFamily='NotoSansKR-Black' fontSize={14} color='#333333'>URBANFIT INVITATIONAL - Scaled</Text>
        </TitleWrapper>
      </TopLineWrapper>
      <DivisionLine />
      <SectionButtonWrapper>
        <SectionButton isClicked={nowSection === 'A'} onClick={() => onClickNowSection('A')}>
          <Text fontFamily='Poppins-Bold' fontSize={14} color='#FFFFFF'>E1</Text>
        </SectionButton>
        <SectionButton style={{ width: '42px' }} isClicked={nowSection === 'B'} onClick={() => onClickNowSection('B')}>
          <Text fontFamily='Poppins-Bold' fontSize={14} color='#FFFFFF'>E2-1</Text>
        </SectionButton>
        <SectionButton style={{ width: '42px' }} isClicked={nowSection === 'C'} onClick={() => onClickNowSection('C')}>
          <Text fontFamily='Poppins-Bold' fontSize={14} color='#FFFFFF'>E2-2</Text>
        </SectionButton>
        <SectionButton style={{ width: '42px' }} isClicked={nowSection === 'D'} onClick={() => onClickNowSection('D')}>
          <Text fontFamily='Poppins-Bold' fontSize={14} color='#FFFFFF'>E3-A</Text>
        </SectionButton>
        <SectionButton style={{ width: '42px' }} isClicked={nowSection === 'E'} onClick={() => onClickNowSection('E')}>
          <Text fontFamily='Poppins-Bold' fontSize={14} color='#FFFFFF'>E3-B</Text>
        </SectionButton>
        {/* <SectionButton isClicked={nowSection === 'E'} onClick={() => onClickNowSection('E')}>
          <Text fontFamily='Poppins-Bold' fontSize={14} color='#FFFFFF'>E5</Text>
        </SectionButton> */}
          <TotalSection isClicked={nowSection === 'Total'} onClick={() => onClickNowSection('Total')}>
            <CrownImage src={CrownIcon} />
          </TotalSection>
      </SectionButtonWrapper>

      <DivisionButtonWrapper>
        <DivisionButton backgroundColor={isClickedDivision === 1 ? '#FF8B48' : '#DBDBDB'} onClick={() => setIsClickedDivision(1)}>
          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF'>Division1 남/여</Text>
        </DivisionButton>
        <DivisionButton backgroundColor={isClickedDivision === 2 ? '#6DD49E' : '#DBDBDB'} onClick={() => setIsClickedDivision(2)}>
          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF'>Division2 남/남</Text>
        </DivisionButton>
      </DivisionButtonWrapper>

      <DivisionLine />

      {
        (nowSection === 'D' || nowSection ==='E') &&
        <GenderDivisionBoxWrapper>
          {
            nowSection === 'D' ?
              <GenderDivisionBox style={{ marginTop: '24px' }} backgroundColor={(isClickedDivision === 1) ? '#FF3131' : '#2F80ED'}>
                <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>{(isClickedDivision === 1) ? '여성A - ATHLETE' : '남성A - ATHLETE'}</Text>
              </GenderDivisionBox>
              :
              <GenderDivisionBox style={{ marginTop: '24px' }} backgroundColor='#2F80ED'>
                <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>남성B - ATHLETE</Text>
              </GenderDivisionBox>
          }
        </GenderDivisionBoxWrapper>
      }

      {
        <TotalWrapper>
          {
            (myRanking !== undefined && isClickedDivision === myDivision) &&
            <TempWrapper>
              <TopLineWrapper>
                <TitleWrapper>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#333333'>{t('내 순위')}</Text>
                </TitleWrapper>
              </TopLineWrapper>
              {
                isLoading ?
                  <RankingTotalWrapper>
                    <RankingLoadingView>
                      <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>{t('순위 계산중...')}</Text>
                    </RankingLoadingView>
                  </RankingTotalWrapper>
                  :
                  <RankingTotalWrapper>
                    <RankingViewWrapper height={myRankTotalHeight} onClick={() => onClickMyRecordDetailButton()}>
                      {
                        <RankingNumberBoxWrapper>
                          {
                            (myRanking.rank > 0 && myRanking.rank < 4) ?
                              <RankingLeaderBoxWrapper>
                                <RankingLeaderBox src={myRanking.rank === 1 ? FirstPlaceImg : myRanking.rank === 2 ? SecondPlaceImg : ThirdPlaceImg} />
                                <RankingImageTextWrapper>
                                  <Text style={{ marginTop: `-8px` }} fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>{myRanking.rank}</Text>
                                </RankingImageTextWrapper>
                              </RankingLeaderBoxWrapper>
                              :
                              <RankingNumberBox>
                                <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>{myRanking.rank}</Text>
                              </RankingNumberBox>
                          }
                        </RankingNumberBoxWrapper>
                      }
                      <RankingClickBoxWrapper>
                        <RankingClickBox height={myRankHeight} >
                          <InfoBox>
                            <LeftWrapper>
                              <Profile src={myRanking.profile === '' ? NoneProfile : myRanking.profile} />
                              <InfoTextWrapper style={{ marginLeft: '8px' }}>
                                <InnerInfoTextWrapper>
                                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>{myRanking.name}</Text>
                                </InnerInfoTextWrapper>
                                <RecordBox>
                                  <RecordTextWrapper>
                                    <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#4F4F4F'>{calcTime(myRanking)}</Text>
                                  </RecordTextWrapper>
                                </RecordBox>
                              </InfoTextWrapper>
                            </LeftWrapper>
                            <DetailButton isClicked={isClickedMyRank} src={RightImg} />
                          </InfoBox>
                          {
                            (nowSection === 'B' || nowSection === 'C' || nowSection === 'D' || nowSection === 'E') &&
                            <MemberColumnView>
                              {
                                myRanking.members.map((data, index) => (
                                  <MemberView key={index}>
                                    { (nowSection !== 'D' && nowSection !== 'E') && <MemberProfile src={data.profile === '' ? NoneProfile : data.profile} /> }
                                    { ((nowSection === 'D' && index === 0) || (nowSection === 'E' && index === 1)) && <MemberProfile src={data.profile === '' ? NoneProfile : data.profile} /> }
                                    {/* { (nowSection === 'E' && index === 1) && <MemberProfile src={data.profile === '' ? NoneProfile : data.profile} /> } */}
                                    <ColorBoxWrapper>
                                      { 
                                        (nowSection !== 'D' && nowSection !== 'E') &&
                                        <ColorBox backgroundColor={data.gender === 'male' ? '#2F80ED' : '#FF3131'}>
                                          <ScaleText fontFamily='NotoSansKR-Medium' fontSize={10} scale={0.8} color='#FFFFFF'>ATHLETE</ScaleText>
                                        </ColorBox>
                                      }
                                      {
                                        ((nowSection === 'D' && index === 0) || (nowSection === 'E' && index === 1)) &&
                                        <ColorBox backgroundColor={data.gender === 'male' ? '#2F80ED' : '#FF3131'}>
                                          <ScaleText fontFamily='NotoSansKR-Medium' fontSize={10} scale={0.8} color='#FFFFFF'>ATHLETE</ScaleText>
                                        </ColorBox>
                                      }
                                      {recordView(myRanking, data, index)}
                                    </ColorBoxWrapper>
                                  </MemberView>
                                ))
                              }
                            </MemberColumnView>
                          }
                          {
                            (nowSection !== 'B' && nowSection !== 'C' && nowSection !== 'D' && nowSection !== 'E') &&
                            <MemberRowView>
                              {
                                myRanking.members.map((data, index) => (
                                  <MemberView key={index}>
                                    <MemberProfile src={data.profile === '' ? NoneProfile : data.profile} />
                                    <ColorBoxWrapper>
                                      <ColorBox backgroundColor={data.gender === 'male' ? '#2F80ED' : '#FF3131'}>
                                        {/* <ColorBox backgroundColor={'#FF3131'}> */}
                                        <ScaleText fontFamily='NotoSansKR-Medium' fontSize={10} scale={0.8} color='#FFFFFF'>ATHLETE</ScaleText>
                                      </ColorBox>
                                      <ColorBoxInTextWrapper>
                                        <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>{data.name}</Text>
                                      </ColorBoxInTextWrapper>
                                    </ColorBoxWrapper>
                                  </MemberView>
                                ))
                              }
                            </MemberRowView>
                          }
                          <BottomLine>
                            <Text fontFamily='NotoSansKR-Thin' fontSize={14} color='#4F4F4F'>Team : </Text>
                            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{myRanking.belong}</Text>
                            {/* <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{myRanking.name}</Text> */}
                          </BottomLine>
                        </RankingClickBox>
                        {
                          nowSection === 'Total' &&
                          <TotalRecordWrapper isClicked={isClickedMyRank && nowSection === 'Total'}>
                            {/* Event 1 */}
                            <EventRowWrapper>
                              <EventBox>
                                <ScaleText fontFamily='Poppins-Bold' fontSize={10} color='#FFFFFF' scale={0.8}>EVENT1</ScaleText>
                              </EventBox>
                              <EventRankingBox backgroundColor={selectTotalEventRankingBoxBackgroundColor(myRanking.event1)}>
                                <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>{myRanking.event1.rank === -1 ? '0' : myRanking.event1.rank}</Text>
                              </EventRankingBox>

                              <EventInfoBox style={{ borderRadius: `13px 13px 0px 13px` }} >
                                <EventInfoTextWrapper>
                                  <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#4F4F4F'>{calcTotalEvent1View(myRanking.event1)}</Text>
                                </EventInfoTextWrapper>
                              </EventInfoBox>
                            </EventRowWrapper>
                            {/* Event 1 */}
                            {/* Event 2 */}
                            <EventRowWrapper style={{ marginTop: '3px' }}>
                              <EventBox>
                                <ScaleText fontFamily='Poppins-Bold' fontSize={10} color='#FFFFFF' scale={0.8}>EVENT2A</ScaleText>
                              </EventBox>
                              <EventRankingBox backgroundColor={selectTotalEventRankingBoxBackgroundColor(myRanking.event2)}>
                                <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>{myRanking.event2.rank === -1 ? '0' : myRanking.event2.rank}</Text>
                              </EventRankingBox>
                              <EventInfoBox style={{ borderRadius: `13px 0px 0px 13px` }} >
                                <EventInfoTextWrapper>
                                  <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#4F4F4F'>{calcTotalEvent2View(myRanking.event2)}</Text>
                                </EventInfoTextWrapper>
                              </EventInfoBox>
                            </EventRowWrapper>
                            {/* Event 2 */}
                            {/* Event 3 */}
                            <EventRowWrapper style={{ marginTop: '3px' }}>
                              <EventBox>
                                <ScaleText fontFamily='Poppins-Bold' fontSize={10} color='#FFFFFF' scale={0.8}>EVENT2B</ScaleText>
                              </EventBox>
                              <EventRankingBox backgroundColor={selectTotalEventRankingBoxBackgroundColor(myRanking.event3)}>
                                <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>{myRanking.event3.rank === -1 ? '0' : myRanking.event3.rank}</Text>
                              </EventRankingBox>
                              <EventInfoBox style={{ borderRadius: `13px 0px 0px 13px` }} >
                                <EventInfoTextWrapper>
                                  <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#4F4F4F'>{calcTotalEvent3View(myRanking.event3)}</Text>
                                </EventInfoTextWrapper>
                              </EventInfoBox>
                            </EventRowWrapper>
                            {/* Event 3 */}
                            {/* Event 4 */}
                            {
                              moment().isAfter(openDate) &&
                              <EventRowWrapper style={{ marginTop: '3px' }}>
                                <EventBox>
                                  <ScaleText fontFamily='Poppins-Bold' fontSize={10} color='#FFFFFF' scale={0.8}>EVENT3A</ScaleText>
                                </EventBox>
                                <EventRankingBox backgroundColor={selectTotalEventRankingBoxBackgroundColor(myRanking.event4)}>
                                  <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>{myRanking.event4.rank === -1 ? '0' : myRanking.event4.rank}</Text>
                                </EventRankingBox>
                                <EventInfoBox style={{ borderRadius: `13px 0px 13px 13px` }} >
                                  <EventInfoTextWrapper>
                                    <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#4F4F4F'>{calcTotalEvent4View(myRanking.event4)}</Text>
                                  </EventInfoTextWrapper>
                                </EventInfoBox>
                              </EventRowWrapper>
                            }
                            {/* Event 4 */}
                            {/* Event 5 */}
                            <EventRowWrapper style={{ marginTop: '3px' }}>
                              <EventBox>
                                <ScaleText fontFamily='Poppins-Bold' fontSize={10} color='#FFFFFF' scale={0.8}>EVENT3B</ScaleText>
                              </EventBox>
                              <EventRankingBox backgroundColor={selectTotalEventRankingBoxBackgroundColor(myRanking.event5)}>
                                <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>{myRanking.event5.rank === -1 ? '0' : myRanking.event5.rank}</Text>
                              </EventRankingBox>
                              <EventInfoBox style={{ borderRadius: `13px 0px 13px 13px` }} >
                                <EventInfoTextWrapper>
                                  <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#4F4F4F'>{calcTotalEvent5View(myRanking.event5)}</Text>
                                </EventInfoTextWrapper>
                              </EventInfoBox>
                            </EventRowWrapper>
                            {/* Event 5 */}
                            {/* Event 6 */}
                            {/* <EventRowWrapper style={{ marginTop: '3px' }}>
                              <EventBox>
                                <ScaleText fontFamily='Poppins-Bold' fontSize={10} color='#FFFFFF' scale={0.8}>HIDDEN2</ScaleText>
                              </EventBox>
                              <EventInfoBox style={{ borderRadius: `13px 0px 13px 13px` }} >
                                <EventRankingBox backgroundColor={selectTotalEventRankingBoxBackgroundColor(myRanking.event6)}>
                                  <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>{myRanking.event6.rank === -1 ? '0' : myRanking.event6.rank}</Text>
                                </EventRankingBox>
                                <EventInfoTextWrapper>
                                  <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#4F4F4F'>{calcTotalEvent6View(myRanking.event6)}</Text>
                                </EventInfoTextWrapper>
                              </EventInfoBox>
                            </EventRowWrapper> */}
                            {/* Event 6 */}
                          </TotalRecordWrapper>
                        }
                      </RankingClickBoxWrapper>
                    </RankingViewWrapper>
                  </RankingTotalWrapper>
              }
            </TempWrapper>
          }
          <TopLineWrapper>
            <TitleWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#333333'>{t('전체 순위')}</Text>
            </TitleWrapper>
          </TopLineWrapper>
          {
            isLoading ?
              <RankingTotalWrapper>
                <RankingLoadingView>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>{t('순위 계산중...')}</Text>
                </RankingLoadingView>
              </RankingTotalWrapper>
              :
              <RankingTotalWrapper>
                {
                  responseRankingList.map((data, index) => (
                    <RankingViewWrapper key={index} height={calcTotalComponentHeight(data)} onClick={() => onClickRecordDetailButton(index)}>
                      {
                        <RankingNumberBoxWrapper>
                          {
                            (data.rank > 0 && data.rank < 4) ?
                              <RankingLeaderBoxWrapper>
                                <RankingLeaderBox src={data.rank === 1 ? FirstPlaceImg : data.rank === 2 ? SecondPlaceImg : ThirdPlaceImg} />
                                <RankingImageTextWrapper>
                                  <Text style={{ marginTop: `-8px` }} fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>{data.rank}</Text>
                                </RankingImageTextWrapper>
                              </RankingLeaderBoxWrapper>
                              :
                              <RankingNumberBox>
                                <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>{data.rank}</Text>
                              </RankingNumberBox>
                          }
                        </RankingNumberBoxWrapper>
                      }
                      <RankingClickBoxWrapper>
                        <RankingClickBox height={calcComponentHeight(data)}>
                          <InfoBox>
                            <LeftWrapper>
                              <Profile src={data.profile === '' ? NoneProfile : data.profile} />
                              <InfoTextWrapper style={{ marginLeft: '8px' }}>
                                <InnerInfoTextWrapper>
                                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>{data.name}</Text>
                                </InnerInfoTextWrapper>
                                <RecordBox>
                                  <RecordTextWrapper>
                                    <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#4F4F4F'>{calcTime(data)}</Text>
                                  </RecordTextWrapper>
                                  <RecordTextWrapper>
                                  </RecordTextWrapper>
                                </RecordBox>
                              </InfoTextWrapper>
                            </LeftWrapper>
                            <DetailButton isClicked={data.isClicked} src={RightImg} />
                          </InfoBox>
                          {
                            (nowSection === 'B' || nowSection === 'C' || nowSection === 'D' || nowSection === 'E') &&
                            <MemberColumnView>
                              {
                                data.members.map((innerData, index) => (
                                  <MemberView key={index}>
                                    {/* <MemberProfile src={innerData.profile === '' ? NoneProfile : innerData.profile} />
                                    <ColorBoxWrapper>
                                      <ColorBox backgroundColor={innerData.gender === 'male' ? '#2F80ED' : '#FF3131'}>
                                        <ScaleText fontFamily='NotoSansKR-Medium' fontSize={10} scale={0.8} color='#FFFFFF'>ATHLETE</ScaleText>
                                      </ColorBox> */}
                                    { (nowSection !== 'D' && nowSection !== 'E') && <MemberProfile src={innerData.profile === '' ? NoneProfile : innerData.profile} /> }
                                    { ((nowSection === 'D' && index === 0) || (nowSection === 'E' && index === 1)) && <MemberProfile src={innerData.profile === '' ? NoneProfile : innerData.profile} /> }
                                    <ColorBoxWrapper>
                                      { 
                                        (nowSection !== 'D' && nowSection !== 'E') &&
                                        <ColorBox backgroundColor={innerData.gender === 'male' ? '#2F80ED' : '#FF3131'}>
                                          <ScaleText fontFamily='NotoSansKR-Medium' fontSize={10} scale={0.8} color='#FFFFFF'>ATHLETE</ScaleText>
                                        </ColorBox>
                                      }
                                      {
                                        ((nowSection === 'D' && index === 0) || (nowSection === 'E' && index === 1)) &&
                                        <ColorBox backgroundColor={innerData.gender === 'male' ? '#2F80ED' : '#FF3131'}>
                                          <ScaleText fontFamily='NotoSansKR-Medium' fontSize={10} scale={0.8} color='#FFFFFF'>ATHLETE</ScaleText>
                                        </ColorBox>
                                      }
                                      {recordView(data, innerData, index)}
                                    </ColorBoxWrapper>
                                  </MemberView>
                                ))
                              }
                            </MemberColumnView>
                          }
                          {
                            (nowSection !== 'B' && nowSection !== 'C' && nowSection !== 'D' && nowSection !== 'E') &&
                            <MemberRowView>
                              {
                                data.members.map((innerData, index) => (
                                  <MemberView key={index}>
                                    <MemberProfile src={innerData.profile === '' ? NoneProfile : innerData.profile} />
                                    <ColorBoxWrapper>
                                      <ColorBox backgroundColor={innerData.gender === 'male' ? '#2F80ED' : '#FF3131'}>
                                        <ScaleText fontFamily='NotoSansKR-Medium' fontSize={10} scale={0.8} color='#FFFFFF'>ATHLETE</ScaleText>
                                      </ColorBox>
                                      <ColorBoxInTextWrapper>
                                        <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>{innerData.name}</Text>
                                      </ColorBoxInTextWrapper>
                                    </ColorBoxWrapper>
                                  </MemberView>
                                ))
                              }
                            </MemberRowView>
                          }
                          <BottomLine>
                            <Text fontFamily='NotoSansKR-Thin' fontSize={14} color='#4F4F4F'>Team : </Text>
                            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.belong}</Text>
                            {/* <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.name}</Text> */}
                          </BottomLine>
                        </RankingClickBox>
                        {
                          nowSection === 'Total' &&
                          <TotalRecordWrapper isClicked={data.isClicked && nowSection === 'Total'}>
                            {/* Event 1 */}
                            <EventRowWrapper>
                              <EventBox>
                                <ScaleText fontFamily='Poppins-Bold' fontSize={10} color='#FFFFFF' scale={0.8}>EVENT1</ScaleText>
                              </EventBox>
                              <EventRankingBox backgroundColor={selectTotalEventRankingBoxBackgroundColor(data.event1)}>
                                <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>{data.event1.rank === -1 ? 'DNF' : data.event1.rank}</Text>
                              </EventRankingBox>
                              <EventInfoBox style={{ borderRadius: `13px 13px 0px 13px` }} >
                                <EventInfoTextWrapper>
                                  <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#4F4F4F'>{calcTotalEvent1View(data.event1)}</Text>
                                </EventInfoTextWrapper>
                              </EventInfoBox>
                            </EventRowWrapper>
                            {/* Event 1 */}
                            {/* Event 2 */}
                            <EventRowWrapper style={{ marginTop: '3px' }}>
                              <EventBox>
                                <ScaleText fontFamily='Poppins-Bold' fontSize={10} color='#FFFFFF' scale={0.8}>EVENT2A</ScaleText>
                              </EventBox>
                              <EventRankingBox backgroundColor={selectTotalEventRankingBoxBackgroundColor(data.event2)}>
                                <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>{data.event2.rank === -1 ? 'DNF' : data.event2.rank}</Text>
                              </EventRankingBox>
                              <EventInfoBox style={{ borderRadius: `13px 0px 13px 13px` }} >
                                <EventInfoTextWrapper>
                                  <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#4F4F4F'>{calcTotalEvent2View(data.event2)}</Text>
                                </EventInfoTextWrapper>
                              </EventInfoBox>
                            </EventRowWrapper>
                            {/* Event 2 */}
                            {/* Event 3 */}
                            <EventRowWrapper style={{ marginTop: '3px' }}>
                              <EventBox>
                                <ScaleText fontFamily='Poppins-Bold' fontSize={10} color='#FFFFFF' scale={0.8}>EVENT2B</ScaleText>
                              </EventBox>
                              <EventRankingBox backgroundColor={selectTotalEventRankingBoxBackgroundColor(data.event3)}>
                                <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>{data.event3.rank === -1 ? 'DNF' : data.event3.rank}</Text>
                              </EventRankingBox>
                              <EventInfoBox style={{ borderRadius: `13px 0px 0px 13px` }} >
                                <EventInfoTextWrapper>
                                  <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#4F4F4F'>{calcTotalEvent3View(data.event3)}</Text>
                                </EventInfoTextWrapper>
                              </EventInfoBox>
                            </EventRowWrapper>
                            {/* Event 3 */}
                            {/* Event 4 */}
                            {
                              moment().isAfter(openDate) &&
                              <EventRowWrapper style={{ marginTop: '3px' }}>
                                <EventBox>
                                  <ScaleText fontFamily='Poppins-Bold' fontSize={10} color='#FFFFFF' scale={0.8}>EVENT3A</ScaleText>
                                </EventBox>
                                <EventRankingBox backgroundColor={selectTotalEventRankingBoxBackgroundColor(data.event4)}>
                                  <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>{data.event4.rank === -1 ? 'DNF' : data.event4.rank}</Text>
                                </EventRankingBox>
                                <EventInfoBox style={{ borderRadius: `13px 0px 13px 13px` }} >
                                  <EventInfoTextWrapper>
                                    <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#4F4F4F'>{calcTotalEvent4View(data.event4)}</Text>
                                  </EventInfoTextWrapper>
                                </EventInfoBox>
                              </EventRowWrapper>
                            }
                            {/* Event 4 */}
                            {/* Event 5 */}
                            <EventRowWrapper style={{ marginTop: '3px' }}>
                              <EventBox>
                                <ScaleText fontFamily='Poppins-Bold' fontSize={10} color='#FFFFFF' scale={0.8}>EVENT3B</ScaleText>
                              </EventBox>
                              <EventRankingBox backgroundColor={selectTotalEventRankingBoxBackgroundColor(data.event5)}>
                                <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>{data.event5.rank === -1 ? 'DNF' : data.event5.rank}</Text>
                              </EventRankingBox>
                              <EventInfoBox style={{ borderRadius: `13px 0px 13px 13px` }} >
                                <EventInfoTextWrapper>
                                  <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#4F4F4F'>{calcTotalEvent5View(data.event5)}</Text>
                                </EventInfoTextWrapper>
                              </EventInfoBox>
                            </EventRowWrapper>
                            {/* Event 5 */}
                            {/* Event 6 */}
                            {/* <EventRowWrapper style={{ marginTop: '3px' }}>
                              <EventBox>
                                <ScaleText fontFamily='Poppins-Bold' fontSize={10} color='#FFFFFF' scale={0.8}>HIDDEN2</ScaleText>
                              </EventBox>
                              <EventInfoBox style={{ borderRadius: `13px 0px 13px 13px` }} >
                                <EventRankingBox backgroundColor={selectTotalEventRankingBoxBackgroundColor(data.event6)}>
                                  <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>{data.event6.rank === -1 ? '0' : data.event6.rank}</Text>
                                </EventRankingBox>
                                <EventInfoTextWrapper>
                                  <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#4F4F4F'>{calcTotalEvent6View(data.event6)}</Text>
                                </EventInfoTextWrapper>
                              </EventInfoBox>
                            </EventRowWrapper> */}
                            {/* Event 6 */}
                          </TotalRecordWrapper>
                        }
                      </RankingClickBoxWrapper>
                    </RankingViewWrapper>
                  ))
                }
              </RankingTotalWrapper>
          }
        </TotalWrapper>
        // :
        // <TotalWrapper>
        //   <WaitWrapper>
        //     <WaitImage src={WaitIcon} />
        //     <Text style={{ marginTop: '8px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>온라인 저징 이후</Text>
        //     <Text style={{ marginTop: '10px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>재업로드 예정입니다</Text>
        //   </WaitWrapper>
        // </TotalWrapper>
      }
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  padding-bottom: 80px;

  width: 100%;
  height: 100%;

  min-height: 100vh;

  background-color: #F9F9F9;
`;

const TopLineWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 90.33%; 
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: 20px;

  width: 100%;
`;

const SectionButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90.33%;
`;

const SectionButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-right: 8px;

  width: 32px;
  height: 32px;

  border-radius: 6px;
  background-color: ${props => props.isClicked ? `#6DD49E` : `#E0E0E0`};

  :hover {
    cursor: pointer;
  }
  
  transition: all 0.3s ease-in-out;
`;

const TotalSection = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 32px;
  height: 32px;

  border-radius: 6px;
  background-color: ${props => props.isClicked ? `#FF8B48` : `#E0E0E0`};

  :hover {
    cursor: pointer;
  }
  
  transition: all 0.3s ease-in-out;
`;

const DivisionLine = styled.div`
  width: 90.33%;
  height: 1px;

  margin: 20px 0px 20px 0px;

  background-color: #E0E0E0;
`;

const GenderSelectWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const GenderSelectBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;

  width: 50%;
  height: 32px;

  border-radius: 10px 10px 0px 0px;
  background-color: ${props => props.isClicked ? '#F9F9F9' : '#E0E0E0'};

  transition: all 0.3s ease-in-out;
`;

const GenderImageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  transition: all 0.3s ease-in-out;
`;

const GenderImage = styled.img`
  width: 11px;
  height: 20px;

  transition: all 0.3s ease-in-out;
`;

const CrownImage = styled.img`
  width: 22px;
  height: 19px;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;  

  width: 100%;
`;

const TempWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;  

  width: 100%;
`;

const RankingTotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
  
  // margin: 4px 0px 80px 0px;
  marigin-top: 4px;

  width: 90.33%;
`;

const RankingLoadingView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 12px;

  width: 100%;
  height: 64px;
`;

const RankingViewWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  margin-top: 12px;

  width: 100%;
  // height: 64px;
  height: ${props => props.height}px;

  transition: all 0.3s ease-in-out;
`;

const RankingNumberBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const RankingLeaderBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const RankingLeaderBox = styled.img`
  width: 40px;
  height: 40px;
`;

const RankingImageTextWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // margin-top: -10px;
`;

const RankingNumberBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;

  border-radius: 12px;
  background-color: #E0E0E0;
`;

const RankingClickBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 87.16%;
`;

const RankingClickBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  align-items: center;

  padding: 0px 16px 0px 12px;

  width: calc(100% - 28px);
  height: ${props => props.height}px;

  overflow: hidden;
  
  border-radius: 12px;
  background-color: #FFFFFF;

  box-shadow: 0px 10px 10px rgba(0, 54, 71, 0.02), 0px 14px 32px rgba(0, 54, 71, 0.05);

  :hover {
    cursor: pointer;
    background-color: #F9F9F9;
  }

  transition: all 0.3s ease-in-out;
`;

const BottomLine = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  // margin-top: 3px;
  gap: 4px;

  width: calc(100% + 30px);
  height: 25px;
  
  background-color: #F2F2F2;
`;

const TotalRecordWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: ${props => props.isClicked ? 10 : 0}px;

  width: 100%;
  // height: ${props => props.isClicked ? 170 : 0}px;
  height: ${props => props.isClicked ? 255 : 0}px;

  overflow: hidden;

  // border-radius: 12px;
  // background-color: #FFFFFF;
  
  transition: all 0.3s ease-in-out;
`;

const EventRowWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  width: 100%;
`;

const EventBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;

  // border-radius: 7px;
  border-radius: 12px 0px 0px 12px;
  background-color: #C4C4C4;
`;

const EventInfoBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  // width: 85.27%;
  width: calc(85.27% - 40px);
  height: 40px;

  background-color: #FFFFFF;
`;

const EventRankingBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;

  // border-radius: 12px;
  border-radius: 0px 12px 12px 0px;
  background-color: ${props => props.backgroundColor};
`;

const EventInfoTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-end;

  margin-left: 8px;

  height: 20px;
`;

const TotalInfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const MemberRowView = styled.div`
  display: flex;
  flex-direction: row;

  flex-wrap: wrap;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const MemberColumnView = styled.div`
  display: flex;
  flex-direction: column;

  flex-wrap: wrap;

  justify-content: space-between;
  align-items: flex-start;

  width: 100%;
`;

const MemberView = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // margin-top: 28px;
  margin-top: 12px;
`;

const MemberProfile = styled.img`
  width: 40px;
  height: 40px;

  margin-right: 8px;

  border-radius: 6px;

  object-fit: contain;
`;

const ColorBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;
`;

const ColorBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 41px;
  height: 21px;

  border-radius: 4px;
  background-color: ${props => props.backgroundColor};
`;

const ColorBoxInTotalTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-end;

  // width: 70%;
  // height: 20px;
  
  // overflow: hidden;

  gap: 4px;
`;

const ColorBoxInTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 41px;
  height: 20px;
  
  overflow: hidden;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: 12px;

  // width: 95.7%;
  width: 100%;
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // gap: 8px;
`;

const Profile = styled.img`
  width: 40px;
  height: 40px;

  border-radius: 6px;
`;

const InfoTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const InnerInfoTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 6px;
`;

const RecordBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const RecordTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
`;

const RxBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 23px;
  height: 13px;

  border-radius: 3px;
  background-color: #FF3131;
`;

const ScaleBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 33px;
  height: 13px;

  border-radius: 3px;
  background-color: #4161AF;
`;

const DetailButton = styled.img`
  width: 9.6px;
  height: 17.7px;

  transform: ${props => props.isClicked ? `rotateZ(90deg)` : `rotateZ(0deg)`};

  transition: all 0.3s ease-in-out;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }

  transition: all 0.3s ease-in-out;
`;

const ScaleText = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
  
  -webkit-transform: scale(${props => props.scale});
  transition: all 0.3s ease-in-out;
`;

const WaitWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 74px;
`;

const WaitImage = styled.img`
  width: 148px;
  height: 62px;

  :hover {
    cursor: default;
  }
`;

const DivisionButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;
  
  margin-top: 15px;

  width: 100%;
  height: 20px;

  background-color: red;
`;

const DivisionButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 50%;
  height: 25px;

  background-color: ${props => props.backgroundColor};

  transition: all 0.3s ease-in-out;
`;

const CompleteBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 32px;
  height: 14px;

  border-radius: 2px;
  background-color: ${props => props.backgroundColor};
`;

const GenderDivisionBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  // width: 100%;
  width: 90.33%;
`;

const GenderDivisionBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  padding: 0px 10px;

  height: 20px;

  border-radius: 4px;
  background-color: ${props => props.backgroundColor};
`;