import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import PictureRightIcon from '../../../Image/PictureRightArrow.png';
import PictureLeftIcon from '../../../Image/PictureLeftArrow.png';

export default function UrbanfitInvitationalSubmitViewModel(props) {
  const {
    eventId,
    section,
    members,

    setIsExistRecord,

    isRx,

    min,
    sec,

    tieBreakMin,
    tieBreakSec,

    firstMemberMin,
    firstMemberSec,

    secondMemberMin,
    secondMemberSec,

    distance,
    completeRep,
    completeRound,
    lastExerciseRep,
    lastExercise,
    firstExercise,
    secondExercise,
    thirdExercise,
    bodyWeight,
    point,
    link,
    picture,
  } = props;

  const { t } = useTranslation();

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })

  const [pictureSize, setPictureSize] = useState(374);
  const [pictureList, setPictureList] = useState([]);
  const [nowIndex, setNowIndex] = useState(0);

  // 화면 크기 추출후 총 사진 크기 계산 //
  useEffect(() => {
    var totalPictureSize = windowSize.width * 0.9033;
    // console.log(totalPictureSize);
    setPictureSize(totalPictureSize);
  }, [windowSize])

  // 사진 구분 //
  useEffect(() => {
    let pictures = picture.split('<-@->');

    setPictureList(pictures[0] === '' ? [] : pictures);
  }, [picture])

  // 이전 사진 클릭시 //
  function onClickPrevPicture() {
    if (nowIndex === 0) return;

    setNowIndex(nowIndex - 1);
  }

  // 다음 사진 클릭시 //
  function onClickNextPicture() {
    if (nowIndex > 5 || pictureList.length === (nowIndex + 1)) return;

    setNowIndex(nowIndex + 1);
  }

  // // 합산 무게 계산 //
  // function decideSumWeight() {
  //   let first = parseFloat(firstExercise === '' ? 0 : firstExercise);
  //   let second = parseFloat(secondExercise === '' ? 0 : secondExercise);
  //   let third = parseFloat(thirdExercise === '' ? 0 : thirdExercise);
  //   let force = parseFloat(distance === '' ? 0 : distance);

  //   return (first + second + third + force).toFixed(2) + ' lbs';
  // }

  // 합산 무게 계산 //
  function decideSumWeight() {
    let first = 0;
    let second = 0;
    let third = 0;
    let force = 0;

    if (members.length > 0) {
      if (firstExercise === '') first = 0;
      else if (members[0].gender === 'female') first = Math.floor(firstExercise * 1.45);
      else first = parseInt(firstExercise);

      if (secondExercise === '') second = 0;
      else if (members[1].gender === 'female') second = Math.floor(secondExercise * 1.45);
      else second = parseInt(secondExercise);

      if (thirdExercise === '') third = 0;
      else if (members[2].gender === 'female') third = Math.floor(thirdExercise * 1.45);
      else third = parseInt(thirdExercise);

      if (distance === '') force = 0;
      else if (members[3].gender === 'female') force = Math.floor(distance * 1.45);
      else force = parseInt(distance);
    }

    return Math.floor(first + second + third + force) + ' lbs';
  }

  return (
    <TopLevelWrapper>
      <TotalWrapper>
        <TitleTextWrapper>
          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>나의 기록</Text>
          <Text onClick={() => setIsExistRecord(false)} fontFamily='NotoSansKR-Medium' fontSize={14} color='#6DD49E'>RuleBook 보기</Text>
        </TitleTextWrapper>
        {
          (section === 'A') &&
          <ComponentWrapper>
            {
              (min + sec) > 0 ?
                <ComponentInnerWrapper style={{ marginTop: `8px` }}>
                  <AdditionComponentWrapper style={{ gap: '8px' }}>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>기록된 시간을 알려주세요</Text>
                    <TimeWrapper>
                      <TimeInputBox>
                        <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{min}</Text>
                        <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>min</Text>
                      </TimeInputBox>
                      <TimeInputBox>
                        <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{sec}</Text>
                        <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>sec</Text>
                      </TimeInputBox>
                    </TimeWrapper>
                  </AdditionComponentWrapper>
                </ComponentInnerWrapper>
                :
                <ComponentInnerWrapper style={{ marginTop: `8px` }}>
                  <AdditionComponentWrapper>
                    <ComponentInnerWrapper>
                      <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>얼마나 많은 랩 수를 했나요?</Text>
                      <TimeWrapper>
                        <InputBox>
                          <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{completeRep}</Text>
                        </InputBox>
                      </TimeWrapper>
                    </ComponentInnerWrapper>
                  </AdditionComponentWrapper>
                </ComponentInnerWrapper>
            }
            <ComponentInnerWrapper style={{ marginTop: `20px` }}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>Tie Break: Sync 21 Overhead Squat 끝난 시간</Text>
              <TimeWrapper>
                <TimeInputBox>
                  <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{tieBreakMin}</Text>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>min</Text>
                </TimeInputBox>
                <TimeInputBox>
                  <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{tieBreakSec}</Text>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>sec</Text>
                </TimeInputBox>
              </TimeWrapper>
            </ComponentInnerWrapper>
          </ComponentWrapper>
        }
        {/* {
          (section === 'B') &&
          <ComponentWrapper>
            <AdditionComponentWrapper>
              <ComponentInnerWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>얼마나 많은 Point를 성공했나요?</Text>
                <TimeWrapper>
                  <InputBox>
                    <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{completeRep}</Text>
                  </InputBox>
                </TimeWrapper>
              </ComponentInnerWrapper>
            </AdditionComponentWrapper>
          </ComponentWrapper>
        } */}
        {
          (section === 'B' && members.length > 0) &&
          <ComponentWrapper style={{ gap: '0px' }}>
            {
              (min + sec) > 0 ?
                <ComponentInnerWrapper style={{ marginTop: `8px` }}>
                  <AdditionComponentWrapper style={{ gap: '8px' }}>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>기록된 시간을 알려주세요</Text>
                    <TimeWrapper>
                      <TimeInputBox>
                        <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{min}</Text>
                        <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>min</Text>
                      </TimeInputBox>
                      <TimeInputBox>
                        <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{sec}</Text>
                        <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>sec</Text>
                      </TimeInputBox>
                    </TimeWrapper>
                  </AdditionComponentWrapper>
                </ComponentInnerWrapper>
                :
                <ComponentInnerWrapper style={{ marginTop: `8px` }}>
                  <AdditionComponentWrapper>
                    <ComponentInnerWrapper>
                      <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>얼마나 많은 Point를 성공했나요?</Text>
                      <TimeWrapper>
                        <InputBox>
                          <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{completeRep}</Text>
                        </InputBox>
                      </TimeWrapper>
                    </ComponentInnerWrapper>
                  </AdditionComponentWrapper>
                </ComponentInnerWrapper>
            }

            <ComponentInnerWrapper style={{ marginTop: `8px` }}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{'선수 : ' + members[0].name}</Text>
              <Event4TotalInputWrapper>
                <Event4InputWrapper>
                  <DistanceInputBox>
                    <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>{firstExercise}</Text>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>lb</Text>
                  </DistanceInputBox>
                </Event4InputWrapper>
                <Event4ExerciseCJBox isClicked={completeRound === 0}>C&J</Event4ExerciseCJBox>
                <Event4ExerciseSnatchBox isClicked={completeRound === 1}>Snatch</Event4ExerciseSnatchBox>
              </Event4TotalInputWrapper>
            </ComponentInnerWrapper>

            <ComponentInnerWrapper style={{ marginTop: `8px` }}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{'선수 : ' + members[1].name}</Text>
              <Event4TotalInputWrapper>
                <Event4InputWrapper>
                  <DistanceInputBox>
                    <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>{secondExercise}</Text>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>lb</Text>
                  </DistanceInputBox>
                </Event4InputWrapper>
                <Event4ExerciseCJBox isClicked={lastExerciseRep === 0}>C&J</Event4ExerciseCJBox>
                <Event4ExerciseSnatchBox isClicked={lastExerciseRep === 1}>Snatch</Event4ExerciseSnatchBox>
              </Event4TotalInputWrapper>
            </ComponentInnerWrapper>

            {/* <ComponentInnerWrapper style={{ marginTop: `8px` }}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{'선수 : ' + members[2].name}</Text>
              <Event4TotalInputWrapper>
                <Event4InputWrapper>
                  <DistanceInputBox>
                    <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>{thirdExercise}</Text>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>lb</Text>
                  </DistanceInputBox>
                </Event4InputWrapper>
              </Event4TotalInputWrapper>
            </ComponentInnerWrapper> */}

          </ComponentWrapper>
        }
        {
          (section === 'C') &&
          <ComponentWrapper style={{ gap: '0px' }}>

            {/* <SuccessBoxWrapper style={{ marginTop: `12px` }}>
              <SuccssBox isClicked={lastExerciseRep === 0}>
                <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>완주 팀</Text>
              </SuccssBox>
              <FailBox isClicked={lastExerciseRep === 1}>
                <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>미완주 팀</Text>
              </FailBox>
            </SuccessBoxWrapper> */}


              <GenderDivisionBox style={{ marginTop: '24px' }} backgroundColor={members[0].gender === 'female' ? '#FF3131' : '#2F80ED'}>
                <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>{members[0].gender === 'female' ? '여성A - ATHLETE' : '남성A - ATHLETE'}</Text>
              </GenderDivisionBox>

            <ComponentInnerWrapper style={{ marginTop: `8px` }}>
              <AdditionComponentWrapper>
                {/* <ComponentTitleWrapper>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>점수 기준: REPS</Text>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>룰북 참고</Text>
                </ComponentTitleWrapper>
                <ComponentTitleWrapper style={{ marginTop: `8px` }}>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>모든 시간은 REPS로 변경하여 제출 합니다</Text>
                </ComponentTitleWrapper> */}
                {
                  (firstMemberMin + firstMemberSec) !== 0 ?
                    <ComponentInnerWrapper style={{ marginTop: `8px` }}>
                      <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>기록된 시간을 알려주세요</Text>
                      <TimeWrapper>
                        <TimeInputBox>
                          <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{firstMemberMin}</Text>
                          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>min</Text>
                        </TimeInputBox>
                        <TimeInputBox>
                          <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{firstMemberSec}</Text>
                          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>sec</Text>
                        </TimeInputBox>
                      </TimeWrapper>
                    </ComponentInnerWrapper>
                    :
                    <ComponentInnerWrapper style={{ marginTop: `8px` }}>
                      <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>얼마나 많은 랩 수를 했나요?</Text>
                      <TimeWrapper>
                        <InputBox>
                          <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{completeRep}</Text>
                        </InputBox>
                      </TimeWrapper>
                    </ComponentInnerWrapper>
                }
              </AdditionComponentWrapper>
            </ComponentInnerWrapper>
            <ComponentInnerWrapper style={{ marginTop: `20px` }}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>Tiebreak :30 Shuttle Run 끝난 시간</Text>
              <TimeWrapper>
                <TimeInputBox>
                  <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{min}</Text>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>min</Text>
                </TimeInputBox>
                <TimeInputBox>
                  <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{sec}</Text>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>sec</Text>
                </TimeInputBox>
              </TimeWrapper>
            </ComponentInnerWrapper>
            <ComponentWrapper>
              <TimeWrapper>
                <InputBox>
                  <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{link === '' ? t('없음') : link}</Text>
                </InputBox>
              </TimeWrapper>
            </ComponentWrapper>

              <GenderDivisionBox style={{ marginTop: '24px' }} backgroundColor='#2F80ED'>
                <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>남성B - ATHLETE</Text>
              </GenderDivisionBox>

            {
              (secondMemberMin + secondMemberSec) !== 0 ?
                <ComponentInnerWrapper style={{ marginTop: `16px` }}>
                      <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>기록된 시간을 알려주세요</Text>
                  <TimeWrapper>
                    <TimeInputBox>
                      <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{secondMemberMin}</Text>
                      <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>min</Text>
                    </TimeInputBox>
                    <TimeInputBox>
                      <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{secondMemberSec}</Text>
                      <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>sec</Text>
                    </TimeInputBox>
                  </TimeWrapper>
                </ComponentInnerWrapper>
                :
                <ComponentInnerWrapper style={{ marginTop: `16px` }}>
                  <AdditionComponentWrapper>
                    <ComponentInnerWrapper style={{ marginTop: `8px` }}>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>얼마나 많은 랩 수를 했나요?</Text>
                      <TimeWrapper>
                        <InputBox>
                          <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{completeRound}</Text>
                        </InputBox>
                      </TimeWrapper>
                    </ComponentInnerWrapper>
                  </AdditionComponentWrapper>
                </ComponentInnerWrapper>
            }
            <ComponentInnerWrapper style={{ marginTop: `20px` }}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>Tiebreak :30 Shuttle Run 끝난 시간</Text>
              <TimeWrapper>
                <TimeInputBox>
                  <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{tieBreakMin}</Text>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>min</Text>
                </TimeInputBox>
                <TimeInputBox>
                  <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{tieBreakSec}</Text>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>sec</Text>
                </TimeInputBox>
              </TimeWrapper>
            </ComponentInnerWrapper>
            <ComponentWrapper>
              <TimeWrapper>
                <InputBox>
                  <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{lastExercise === '' ? t('없음') : lastExercise}</Text>
                </InputBox>
              </TimeWrapper>
            </ComponentWrapper>

          </ComponentWrapper>
        }
        {/* {
          section === 'A' &&
          <ComponentWrapper>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>EVENT {t('기록 영상')}</Text>
            <TimeWrapper>
              <InputBox>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{link === '' ? t('없음') : link}</Text>
              </InputBox>
            </TimeWrapper>
          </ComponentWrapper>
        } */}
        {
          section === 'B' &&
          <ComponentWrapper>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{'선수 : ' + members[0].name + ' 기록 영상'}</Text>
            <TimeWrapper>
              <InputBox>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{link === '' ? t('없음') : link}</Text>
              </InputBox>
            </TimeWrapper>
          </ComponentWrapper>
        }
        {
          section === 'B' &&
          <ComponentWrapper>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{'선수 : ' + members[1].name + ' 기록 영상'}</Text>
            <TimeWrapper>
              <InputBox>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{lastExercise === '' ? t('없음') : lastExercise}</Text>
              </InputBox>
            </TimeWrapper>
          </ComponentWrapper>
        }
        {
          pictureList.length !== 0 &&
          <ComponentWrapper>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('운동 사진')}</Text>
            <PictureWrapper size={pictureSize}>
              <UserPicture src={pictureList[nowIndex]} />
              <ButtonWrapper size={pictureSize}>
                <ImageMoveButtonWrapper>
                  {
                    nowIndex !== 0 ?
                      <MoveButton onClick={onClickPrevPicture}>
                        <ImageMoveButton src={PictureLeftIcon} />
                      </MoveButton>
                      :
                      <MoveButton />
                  }
                  {
                    (nowIndex !== 4 && pictureList.length >= 2 && pictureList.length !== (nowIndex + 1)) ?
                      <MoveButton onClick={onClickNextPicture}>
                        <ImageMoveButton src={PictureRightIcon} />
                      </MoveButton>
                      :
                      <MoveButton />
                  }
                </ImageMoveButtonWrapper>
              </ButtonWrapper>
            </PictureWrapper>
          </ComponentWrapper>
        }
      </TotalWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  padding: 20px 0px 20px 0px;

  width: 100%;

  background-color: #F9F9F9;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90.33%;
`;

const TotalSumWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 20px;

  width: 100%;

  gap: 8px;
`;

const AdditionComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  // margin-top: 20px;

  // gap: 20px;
`;

const ComponentTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: 12px;

  width: 100%;
`;

const ComponentInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  gap: 8px;
`;

const TitleTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const TimeWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const TimeInputBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding: 0px 12px 0px 12px;

  width: calc(48.39% - 26px);
  height: 32px;

  border: 1px solid #CBCBCB;
  border-radius: 12px;
  background-color: #F6F6F6;
`;



const Event4TotalInputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const Event4InputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 55%;
`;

const Event4ExerciseCJBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 20%;
  height: 32px;

  border-radius: 8px;
  border: ${props => props.isClicked ? `1px solid #6DD49E` : `1px solid #CBCBCB`};
  background-color: ${props => props.isClicked ? `#6DD49E` : `#F6F6F6`};

  font-family: Poppins-Medium;
  font-size: 14px;
  
  color ${props => props.isClicked ? '#FFFFFF' : '#4F4F4F'};

  transition: all 0.3s ease-in-out;
`;

const Event4ExerciseSnatchBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 20%;
  height: 32px;

  border-radius: 8px;
  border: ${props => props.isClicked ? `1px solid #FF8B48` : `1px solid #CBCBCB`};
  background-color: ${props => props.isClicked ? `#FF8B48` : `#F6F6F6`};

  font-family: Poppins-Medium;
  font-size: 14px;
  
  color ${props => props.isClicked ? '#FFFFFF' : '#4F4F4F'};

  transition: all 0.3s ease-in-out;
`;


const DistanceInputBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  overflow: hidden;

  padding: 0px 12px 0px 12px;

  width: calc(100% - 26px);
  height: 32px;

  border: 1px solid #CBCBCB;
  border-radius: 12px;
  background-color: #F6F6F6;
`;

const InputBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  overflow: hidden;

  padding: 0px 12px 0px 12px;

  width: calc(100% - 26px);
  height: 32px;

  border: 1px solid #CBCBCB;
  border-radius: 12px;
  background-color: #F6F6F6;
`;

const Event8Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  gap: 8px;
`;

const ExerciseInputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const NumberBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 27px;
  height: 18px;

  border-radius: 6px;
  background-color: #FF8B48;
`;

const GreenNumberBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 27px;
  height: 18px;

  border-radius: 6px;
  background-color: #6DD49E;
`;

const WeightInputBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding: 0px 12px 0px 12px;

  width: calc(85% - 26px);
  height: 38px;

	border: 1px solid #E8E8E8;
  border-radius: 12px;
  background-color: #FFFFFF;
`;

const TotalWeightWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const TotalWeightTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // width: calc(40.9% - 2px);
  width: calc(35% - 2px);
  height: 38px;

  gap: 2px;

  border: 1px solid #E0E0E0;
  border-radius: 12px;
  background-color: #FFFFFF;
`;

const RedLine = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 60%;
  height: 1px;

  background-color: #FF3131;
`;

const PictureWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: ${props => props.size}px;
  height: ${props => props.size}px;

  border-radius: 12px;

  overflow: hidden;
`;

const UserPicture = styled.img`
  width: 100%;
  height: 100%;

  object-fit: cover;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const ButtonWrapper = styled.div`
  position: absolute;

  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.size}px;
  height: ${props => props.size}px;
`;

const ImageMoveButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding: 0px 12px 0px 12px;

  width: calc(100% - 24px);
`;

const MoveButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 30px;
  height: 30px;
`;

const ImageMoveButton = styled.img`
  width: 30px;
  height: 30px;
`;


const SizeButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const RxButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 48.39%;
  height: 37px;

  border-radius: 12px;
  background-color: ${props => props.isClicked ? `#FF3131` : `#E0E0E0`};
  
  transition: all 0.3s ease-in-out;
`;

const ScaleButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 48.39%;
  height: 37px;

  border-radius: 12px;
  background-color: ${props => props.isClicked ? `#4161AF` : `#E0E0E0`};
  
  transition: all 0.3s ease-in-out;
`;


const SuccessBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const SuccssBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 47.5%;
  height: 34px;

  border-radius: 12px;
  background-color: ${props => props.isClicked ? '#6DD49E' : '#CBCBCB'};

  transition: all 0.3s ease-in-out;
`;

const FailBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 47.5%;
  height: 34px;

  border-radius: 12px;
  background-color: ${props => props.isClicked ? '#D92828' : '#CBCBCB'};

  transition: all 0.3s ease-in-out;
`;

const GenderDivisionBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  padding: 0px 10px;

  height: 20px;

  border-radius: 4px;
  background-color: ${props => props.backgroundColor};
`;